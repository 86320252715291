import { Formik, Form } from  'formik' ; 
import {addDays} from 'date-fns';
import { FormBookingType, BookingFormType, UpdateBookingType } from "../../../redux/types/bookingTypes";

import BookingHeader from '../BookingHeader';
import BookingObjectBlock from '../BookingObjectBlock';
import BookingPersonBlock from '../BookingPersonBlock';
import BookingContactBlock from '../BookingContactBlock';
import BookingCommentBlock from '../BookingCommentBlock';

import styles from "./NewBookingWrap.module.scss";
import Button from '../../Button';
import { ButtonType, ModalWindowTypes } from '../../../utils/@globalTypes';

import ScrollToError from '../ScrollToError';
import { formatDate, getSex } from '../functions';
import { useDispatch } from 'react-redux';
import { createBooking } from '../../../redux/reducers/bookingSlice';
import { setModalWindowData } from '../../../redux/reducers/pageSlice';
import useValidationSchema from '../validationSchema';

const NewBookingWrap = () => { 
  const validationSchema = useValidationSchema()
  
  const initialValues:FormBookingType={          
    rental_object: undefined,   
    status: "new",
    check_in_date: new Date(),
    check_out_date: addDays(new Date(),1),    
    check_in_time: "",
    check_out_time: "",
    adult: 1,
    children:[],
    has_animals: false,  
    animals_info:"",
    total: undefined,
    paid: undefined,
    rest: undefined,
    last_name: "", 
    first_name: "",    
    second_name:undefined,
    nationality: "",
    birth_day:undefined,
    sex:"",
    email: "", 
    phone: {
      country_code: "by",
      phone_code: "+375",
      phone_number:""
    },   
    telegram:"",
    comment:"",
  } 
  const dispatch = useDispatch(); 
  const close = () => {
    dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE })); 
  } 
      
  return (
   
      <div className={styles.formContainer}>
        <Formik 
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur = {false}          
          validateOnChange ={false}   
          validateOnMount ={false}  
          onSubmit={        
           (values, setFieldTouched)=>{
            
            const newBooking:UpdateBookingType = {
            status: values.status,     
            rental_object: values.rental_object,   
            check_in_date: formatDate(values.check_in_date),
            check_out_date: formatDate(values.check_out_date),    
            check_in_time: values.check_in_time,
            check_out_time: values.check_out_time,
            adult: values.adult,
            children:values.children,
            has_animals: values.has_animals,  
            animals_info:values.animals_info,
            total: values.total,
            paid: values.paid||"0.00",
            last_name: values.last_name, 
            first_name: values.first_name,    
            second_name:values.second_name,
            nationality: values.nationality,
            birth_day:values.birth_day&&formatDate(values.birth_day),
            sex:values.sex?getSex(values.sex):"",
            email: values.email, 
            phone: {
              country_code: values.phone.country_code,
              phone_code: values.phone.phone_code,
              phone_number: values.phone.phone_number
              },   
              telegram:values.telegram,
              comment:values.comment,
            };
                    
            try {
              
              dispatch(createBooking(newBooking));     
            } catch (error) {
              console.error('Ошибка отправки:', error);
            }    
          }}   
          >
            {(formik) => (
            <Form >
              <BookingHeader 
                windowType ={BookingFormType.new}
                formik={formik}
                windowClose={close}                 
            />
            <ScrollToError /> 
              <main className={styles.mainContainer}> 
                <BookingObjectBlock 
                  windowType ={BookingFormType.new}
                  formik={formik}                     
                />
                <BookingPersonBlock 
                  windowType ={BookingFormType.new}
                  formik={formik}                 
                />
                <BookingContactBlock 
                  windowType ={BookingFormType.new} 
                  formik={formik}                    
                />
                <BookingCommentBlock
                  windowType ={BookingFormType.new} 
                  formik={formik}                        
                />
                <div className={styles.btnSave}>
                  <Button 
                  title="Сохранить" 
                  disabled={!formik.dirty}
                  type={ButtonType.PRIMARY_SMALL} 
                  actionType="submit"                                      
                  />  
                </div>
              </main>
                           
            </Form>
          )}
        </Formik> 
      </div>
    
  )
}

export default NewBookingWrap ;


