import { FC } from "react";
import styles from './CheckerboardCell.module.scss';
import CheckerboardBanner from "../CheckerboardBanner";
import classNames from "classnames";
import { BookingType } from "../../redux/types/bookingTypes";
import moment from "moment";

type CheckerboardCellProps= {
  itemWidth:number,
  id:number|string,
  booking?:BookingType | undefined,
  longBooking?: BookingType | null | undefined,
  index:number,
  day:moment.Moment,
  isToday: boolean,
  isSelected: boolean,
  isWeekend: boolean
}

const CheckerboardCell:FC<CheckerboardCellProps> = ({
 itemWidth,
 id,
 booking,
 longBooking,
 index,
 day,
 isToday,
 isSelected,
 isWeekend
}) => {
  
  return(
    <div className={classNames(styles.tableGrid,{  
      [styles.weekendGrid]:isWeekend,
      [styles.todayGrid]:isToday,
      [styles.selectedGrid]:isSelected
      })} 
      id={`id${id}${day.unix()}`}
      style={{
        width:itemWidth                  
      }} 
    >
    {booking && 
      <CheckerboardBanner 
        booking={booking} 
        cellWidth={itemWidth} 
        key={index}
        currentDay={day}
        type="start"
      />}
    {longBooking && 
      <CheckerboardBanner 
        booking={longBooking} 
        cellWidth={itemWidth} 
        key={index}
        currentDay={day}
        type="ending"       
      />}
  </div>    
  )
}

export default CheckerboardCell;