import React, { FC } from "react";
import classNames from "classnames";
import styles from "./RadioButton.module.scss";

type RadioButtonProps = {
  onClick: (key: number) => void;
  btnsList: string[]|JSX.Element[];
  // btnsList: RadioButtonTypes;
  activeTab?: number;
  size?: "small" | "big" | "booking" | "price";
  isDisabled?: boolean;
  name?:string;
  id?:string
};

const RadioButton: FC<RadioButtonProps> = ({ onClick, btnsList, activeTab, size, isDisabled, name, id }) => {

  const onTabClick = (key: number) => () => onClick(key);
  
  if(isDisabled){
    return(
      <div className={styles.disabled}>
        {activeTab!==undefined?btnsList[activeTab]:"-"}
      </div>
    )
  }
  
  return (
    <div className={classNames(styles.tabsWrapper, size==="booking"&& styles.wrapBooking)}>
      {btnsList.map((item, index) => {
        return (
          <button
            name={name}
            id={id}
            type="button"
            key={index}
            className={classNames(styles.tab, {
              [styles.activeTab]: activeTab === index,
              [styles.notActiveTab]: activeTab!==undefined && activeTab>-1&& activeTab !== index,
              [styles.big]: size === "big",
              [styles.booking]: size === "booking",
              [styles.price]: size === "price"
            })}
            onClick={onTabClick(index)}            
          >
            {item }
          </button>
        );
      })}
    </div>
  );
};

export default RadioButton;
