import { create } from "apisauce";
import { ConfirmCodePayload, CreateOwnerPayload, SignInUserPayload } from "../types/authTypes";
import {
  CreateObjectPayload,
  GetMainObjectsPayload,
  ObjectTypesPayload,
  UpdateObjectPayloadData,
} from "../types/mainObjectTypes";
import { LimitWithOffsetPayload } from "../types/@types";
import { API_HOST } from "../../utils/config";
import {
  GetEntityInfoResponseData,
  GetIndividualInfoResponseData,
  UpdateOwnerData,
} from "../types/userTypes";
import {
  CreateRentalObjectPayload,
  UpdateRentalImagesData,
  UpdateRentalImagesPayload,
  UpdateRentalObjectData,
} from "../types/rentalObjectTypes";
import { UpdateBookingType, BookingType } from "../types/bookingTypes";
import {
  CreateRoomPayload,
  RentalObjectRoomTypesPayload,
  UpdateRoomPayloadData,
} from "../types/roomTypes";
import { CertainDayPriceType, CreateCertainDayPricePayload, PriceType } from "../types/pricesTypes";

const API = create({
  baseURL: `${API_HOST}/api`,
});

// Auth

const createOwnerRequest = (data: CreateOwnerPayload) => {
  return API.post("/users/owner/", data.createOwnerData);
};

const confirmActivateCodeRequest = (data: ConfirmCodePayload) => {
  return API.post("/email/confirm/", data);
};

const sendActivateCodeRequest = (email: string) => {
  return API.post("/email/send_code/", { email });
};

const signInRequest = (data: SignInUserPayload) => {
  return API.post("/token/", data);
};

const getNewTokensRequest = (refresh: string) => {
  return API.post("/token/refresh/", { refresh });
};

const getCurrentUserInfoRequest = (token: string) => {
  return API.get(
    `/users/me/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const setStatisticRequest = (data: any) => {
  return API.post(`/statistic/`, data);
};

// Main object

const getMainObjectTypesRequest = (data?: ObjectTypesPayload) => {
  return API.get("/main_objects/type/", data);
};

const createMainObjectRequest = (token: string, data: CreateObjectPayload) => {
  return API.post("/main_objects/objects/", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getMainObjectListRequest = (token: string, data: GetMainObjectsPayload) => {
  return API.get(`/main_objects/objects/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getMainObjectRequest = (token: string, id: string) => {
  return API.get(
    `/main_objects/objects/${id}/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const removeMainObjectRequest = (token: string, id: string) => {
  return API.delete(
    `/main_objects/objects/${id}/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateMainObjectRequest = (token: string, id: string, data: UpdateObjectPayloadData) => {
  return API.patch(`/main_objects/objects/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Features

// const createFeatureRequest = (token: string, data: any) => {
//   return API.post(`/features/`, data, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
// };

// const getFeaturesRequest = (data?: LimitWithOffsetPayload) => {
//   return API.get(`/features/`, data);
// };

// Rental object

const getRentalObjectTypesRequest = (data?: ObjectTypesPayload) => {
  return API.get(`/rental_objects_types/`, data);
};

const createRentalObjectRequest = (token: string, data: CreateRentalObjectPayload) => {
  return API.post(`/rental_objects/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getRentalObjectListRequest = (
  token: string,
  data: LimitWithOffsetPayload & { main_object: number }
) => {
  return API.get(`/rental_objects/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const removeRentalObjectRequest = (token: string, id: number) => {
  return API.delete(
    `/rental_objects/${id}/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getSingleRentalObjectRequest = (token: string, id: number) => {
  return API.get(
    `/rental_objects/${id}/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateRentalObjectRequest = (token: string, id: number, data: UpdateRentalObjectData) => {
  return API.patch(`/rental_objects/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getRentalObjectFeaturesRequest = (token: string, id: number) => {
  return API.get(
    `/rental_objects/${id}/features/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// Rental Object Images

const updateRentalImagesRequest = (token: string, data: UpdateRentalImagesPayload) => {
  const formData = new FormData();

  data.old_images.forEach((id) => {
    formData.append("old_images", id.toString());
  });

  data.old_images_orders.forEach((order) => {
    formData.append("old_images_orders", order.toString());
  });

  data.new_images.forEach((file) => {
    formData.append("new_images", file, file.name);
  });

  data.new_images_orders.forEach((order) => {
    formData.append("new_images_orders", order.toString());
  });

  formData.append("rental_object", data.rental_object.toString());

  return API.post(`/images/`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getRentalImagesRequest = (token: string, id: number) => {
  return API.get(
    `/rental_objects/${id}/images/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const removeImageRequest = (token: string, id: number) => {
  return API.delete(
    `/images/${id}/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// const updateImagesOrders = (token: string, data: { id: number; order: number }[]) => {
//   return API.post(`/images/orders/`, data, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
// };

// Rental Object Rooms

const getRentalObjectRoomTypesRequest = (data?: RentalObjectRoomTypesPayload) => {
  return API.get(`/rooms_types/`, data);
};

const getCurrentRentalObjectRoomsRequest = (token: string, id: number) => {
  return API.get(
    `/rental_objects/${id}/rooms/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const createRoomRequest = (token: string, data: CreateRoomPayload) => {
  return API.post(`/rooms/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateRoomRequest = (token: string, id: number, data: UpdateRoomPayloadData) => {
  return API.patch(`/rooms/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const removeRoomRequest = (token: string, id: number) => {
  return API.delete(
    `/rooms/${id}/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// Rental Object Prices

const getRentalObjectPricesRequest = (token: string, id: number) => {
  return API.get(
    `/rental_objects/${id}/price/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateRentalObjectPricesRequest = (token: string, id: number, data: PriceType) => {
  return API.patch(`/prices/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getRentalObjectCertainDayPricesRequest = (token: string, id: number) => {
  return API.get(
    `/rental_objects/${id}/certain_day_prices/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const createRentalObjectCertainDayPriceRequest = (
  token: string,
  data: CreateCertainDayPricePayload
) => {
  return API.post(`/certain_day_prices/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const removeRentalObjectCertainDayPriceRequest = (token: string, id: number) => {
  return API.delete(
    `/certain_day_prices/${id}/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateRentalObjectCertainDayPriceRequest = (
  token: string,
  id: number,
  data: CertainDayPriceType
) => {
  return API.patch(`/certain_day_prices/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Rental Object Booking Settings

const getBookingSettingsRequest = (token: string, id: number) => {
  return API.get(
    `/rental_objects/${id}/booking_setting/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateBookingSettingsRequest = (token: string, id: number, data: any) => {
  return API.patch(`/booking_setting/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Owner

const getUserSettingsRequest = (token: string, id: number) => {
  return API.get(
    `/users/owner/${id}/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateUserSettingsRequest = (token: string, id: number, data: UpdateOwnerData) => {
  return API.patch(`/users/owner/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Organization - Individual

const getIndividualSettingsRequest = (token: string, id: number) => {
  return API.get(
    `/users/individual/${id}/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateIndividualSettingsRequest = (
  token: string,
  id: number,
  data: GetIndividualInfoResponseData
) => {
  return API.patch(`/users/individual/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Organization - Entity

const getEntitySettingsRequest = (token: string, id: number) => {
  return API.get(
    `/users/entity/${id}/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const updateEntitySettingsRequest = (
  token: string,
  id: number,
  data: GetEntityInfoResponseData
) => {
  return API.patch(`/users/entity/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// checkerboard
const getCheckerboardList = (token: string) => {
  return API.get(
    `/main_objects/objects/chess/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// reservation
const getBookingList = (token: string) => {
  return API.get(
    `/reservations/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const updateBookingRequest = (token: string, id: number, data: UpdateBookingType) => {
  return API.patch(`/reservations/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
const createBookingRequest = (token: string, data: BookingType) => {
  return API.post(`/reservations/`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const removeBookingRequest = (token: string, id: number) => {
  return API.delete(
    `/reservations/${id}/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// tariffs

const getTariffsInfo = (token: string) => {
  return API.get(
    `/tarif_info/`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export default {
  createOwnerRequest,
  signInRequest,
  getMainObjectTypesRequest,
  createMainObjectRequest,
  getNewTokensRequest,
  getCurrentUserInfoRequest,
  setStatisticRequest,
  //
  getMainObjectListRequest,
  getMainObjectRequest,
  updateMainObjectRequest,
  getRentalObjectTypesRequest,
  getRentalObjectRoomTypesRequest,
  createRentalObjectRequest,
  getRentalObjectListRequest,
  removeRentalObjectRequest,
  getSingleRentalObjectRequest,
  updateRentalObjectRequest,
  updateRentalImagesRequest,
  getRentalImagesRequest,
  removeImageRequest,
  //updateImagesOrders,
  confirmActivateCodeRequest,
  sendActivateCodeRequest,
  getUserSettingsRequest,
  updateUserSettingsRequest,
  getIndividualSettingsRequest,
  updateIndividualSettingsRequest,
  getEntitySettingsRequest,
  updateEntitySettingsRequest,
  removeMainObjectRequest,
  getCheckerboardList,
  getBookingList,
  getRentalObjectFeaturesRequest,
  createBookingRequest,
  removeBookingRequest,
  updateBookingRequest,
  getCurrentRentalObjectRoomsRequest,
  createRoomRequest,
  updateRoomRequest,
  removeRoomRequest,
  getRentalObjectPricesRequest,
  updateRentalObjectPricesRequest,
  getRentalObjectCertainDayPricesRequest,
  createRentalObjectCertainDayPriceRequest,
  removeRentalObjectCertainDayPriceRequest,
  updateRentalObjectCertainDayPriceRequest,
  getBookingSettingsRequest,
  updateBookingSettingsRequest,
  getTariffsInfo
};
