import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  // addNewImages,
  createRentalObject,
  getCurrentRentalImages,
  getCurrentRentalObject,
  getRentalObjectFeatures,
  getRentalObjectList,
  getRentalObjectTabsData,
  getRentalObjectTypes,
  removeRentalObject,
  removeRentalObjectFromList,
  RentalObjectSelectors,
  setCurrentRentalImages,
  setCurrentRentalObject,
  setIsLoadingRentalObjectCard,
  setRentalObjectFeatures,
  setRentalObjectList,
  setRentalObjectTypes,
  updateRentalObject,
  updateRentalObjectAllBasicSettings,
  updateRentalObjectImages,
} from "../reducers/rentalObjectSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import callCheckingAuth from "./callCheckingAuth";
import API from "../api";
import {
  CreateRentalObjectPayloadData,
  CurrentRentalObject,
  GetRentalObjectListPayload,
  RentalImagesList,
  RentalObject,
  RentalObjectFeaturesResponseData,
  RentalObjectTypesResponseData,
  RentalObjectsResponseData,
  UpdateRentalImagesData,
  UpdateRentalImagesPayload,
  UpdateRentalObjectAllBasicSettingsPayload,
  UpdateRentalObjectPayload,
} from "../types/rentalObjectTypes";
import { LoaderTypes, MessageTypes, ModalWindowTypes } from "../../utils/@globalTypes";
import { ObjectTypesPayload } from "../types/mainObjectTypes";
import { setLoadersData, setMessage, setModalWindowData } from "../reducers/pageSlice";
import { ErrorResponseData } from "../types/@types";
import { setErrorResponeData } from "../reducers/authSlice";
import { getCurrentRentalObjectRoomsWorker } from "./roomSaga";
import { getCurrentRentalObjectRooms } from "../reducers/roomSlice";
import { getCertainDayPricesWorker, getPricesWorker } from "./pricesSaga";
import { getCertainDayPrices, getPrices } from "../reducers/pricesSlice";
import { getBookingSettingsWorker } from "./bookingSettingsSaga";
import { getBookingSettings } from "../reducers/bookingSettingsSlice";
import { convertFileToBase64 } from "../../utils/functions";

function* getRentalObjectTypesWorker(action: PayloadAction<ObjectTypesPayload>) {
  const { ok, data, status }: ApiResponse<RentalObjectTypesResponseData> = yield call(
    API.getRentalObjectTypesRequest,
    action.payload
  );

  if (data && ok) {
    const optionsList = data.results.map((item) => {
      return { value: item.id.toString(), label: item.title };
    });
    yield put(setRentalObjectTypes(optionsList));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения типов сдаваемого объекта",
          code: status,
        })
      );
    }
  }
}

function* createRentalObjectWorker(action: PayloadAction<CreateRentalObjectPayloadData>) {
  yield put(setLoadersData({ type: LoaderTypes.CREATE_RENTAL_OBJECT_POPUP, value: true }));

  const { data, callback } = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<RentalObject, ErrorResponseData> = yield callCheckingAuth(
    API.createRentalObjectRequest,
    data
  );

  if (responseData && ok && callback) {
    yield put(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
    callback(responseData.id);
  } else {
    if (status) {
      if (responseData && !ok && status === 400) {
        yield put(setErrorResponeData(responseData));
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка создания сдаваемого объекта",
            code: status,
          })
        );
      }
    }
  }

  yield put(setLoadersData({ type: LoaderTypes.CREATE_RENTAL_OBJECT_POPUP, value: false }));
}

export function* getRentalObjectListWorker(action: PayloadAction<GetRentalObjectListPayload>) {
  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<RentalObjectsResponseData> = yield callCheckingAuth(
    API.getRentalObjectListRequest,
    action.payload
  );

  if (responseData && ok) {
    yield put(setRentalObjectList(responseData.results));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения списка сдаваемых объектов",
          code: status,
        })
      );
    }
  }
}

function* removeRentalObjectWorker(
  action: PayloadAction<{ main_object: string; rental_object: number }>
) {
  const { main_object, rental_object } = action.payload;

  yield put(
    setIsLoadingRentalObjectCard({
      id: rental_object,
      value: true,
    })
  );

  const { ok, status }: ApiResponse<undefined> = yield callCheckingAuth(
    API.removeRentalObjectRequest,
    rental_object
  );

  if (ok) {
    yield put(removeRentalObjectFromList(rental_object));
  } else {
    yield put(
      setIsLoadingRentalObjectCard({
        id: rental_object,
        value: false,
      })
    );

    if (status) {
      if (status === 404) {
        yield put(getRentalObjectList({ main_object }));

        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Карточка объекта обновлена или удалена",
            code: status,
          })
        );
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка удаления карточки объекта",
            code: status,
          })
        );
      }
    }
  }
}

function* getCurrentRentalObjectWorker(action: PayloadAction<number>) {
  const { ok, data, status }: ApiResponse<CurrentRentalObject> = yield callCheckingAuth(
    API.getSingleRentalObjectRequest,
    action.payload
  );

  if (data && ok) {
    yield put(setCurrentRentalObject(data));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения данных сдаваемого объекта",
          code: status,
        })
      );
    }
  }
}

function* getCurrentFeaturesListWorker(action: PayloadAction<number>) {
  const { ok, data, status }: ApiResponse<RentalObjectFeaturesResponseData> =
    yield callCheckingAuth(API.getRentalObjectFeaturesRequest, action.payload);

  if (data && ok) {
    yield put(setRentalObjectFeatures(data.results));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения данных сдаваемого объекта",
          code: status,
        })
      );
    }
  }
}

function* getCurrentRentalImagesWorker(action: PayloadAction<number>) {
  const { ok, data, status }: ApiResponse<RentalImagesList> = yield callCheckingAuth(
    API.getRentalImagesRequest,
    action.payload
  );

  if (data && ok) {
    yield put(setCurrentRentalImages(data));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения изображений",
          code: status,
        })
      );
    }
  }
}

function* updateRentalObjectImagesWorker(action: PayloadAction<UpdateRentalImagesData>) {
  const { removedIdList, images, rental_object, clearRemovedListCallback } = action.payload;

  const newFiles = images.filter((item) => item.value.isNew).map((item) => item.value.image.file);
  const oldFiles = images.filter((item) => !item.value.isNew).map((item) => item.id);
  const newImagesOrders: number[] = [];
  const oldImagesOrders: number[] = [];

  images.forEach((image, index) => {
    const order = index + 1;

    if (image.value.isNew) {
      newImagesOrders.push(order);
    } else {
      oldImagesOrders.push(order);
    }
  });

  let initialSuccessCount = 1;
  let loadedSuccessCount = 0;
  removedIdList.length && removedIdList.forEach(() => initialSuccessCount++);

  // Преобразование в base64
  // const base64Promises = newFiles.map((file) => call(convertFileToBase64, file));

  // const base64Files: string[] = yield all(base64Promises);

  // Конечные данные
  const updateData: UpdateRentalImagesPayload = {
    old_images: oldFiles,
    old_images_orders: oldImagesOrders,
    new_images: newFiles,
    new_images_orders: newImagesOrders,
    rental_object,
  };

  const rejectedList = [];

  if (removedIdList.length) {
    for (let i = 0; i < removedIdList.length; i++) {
      const { ok: removeOk }: ApiResponse<undefined> = yield callCheckingAuth(
        API.removeImageRequest,
        removedIdList[i]
      );

      if (removeOk) {
        loadedSuccessCount++;
      } else {
        rejectedList.push(removedIdList[i]);
      }

      if (rejectedList.length) {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка удаления изображения",
            code: 404,
          })
        );
      }
    }
    clearRemovedListCallback();
  }

  const { ok, data, status }: ApiResponse<RentalImagesList> = yield callCheckingAuth(
    API.updateRentalImagesRequest,
    updateData
  );

  if (ok) {
    loadedSuccessCount++;
  }

  if (!data && !ok && status) {
    yield put(
      setMessage({
        status: MessageTypes.ERROR,
        message: "Ошибка получения изображений",
        code: status,
      })
    );
  }

  if (initialSuccessCount === loadedSuccessCount) {
    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Изменения сохранены",
        code: 200,
      })
    );
  }

  yield put(getCurrentRentalImages(action.payload.rental_object));
}

function* getRentalObjectTabsDataWorker(action: PayloadAction<number>) {
  yield put(setLoadersData({ type: LoaderTypes.RENTAL_OBJECT_EDITING_PAGE, value: true }));

  yield all([
    getCurrentRentalObjectWorker(getCurrentRentalObject(action.payload)),
    getCurrentFeaturesListWorker(getRentalObjectFeatures(action.payload)),
    getCurrentRentalImagesWorker(getCurrentRentalImages(action.payload)),
    getCurrentRentalObjectRoomsWorker(getCurrentRentalObjectRooms(action.payload)),
    getPricesWorker(getPrices(action.payload)),
    getCertainDayPricesWorker(getCertainDayPrices(action.payload)),
    getBookingSettingsWorker(getBookingSettings(action.payload)),
  ]);

  yield put(setLoadersData({ type: LoaderTypes.RENTAL_OBJECT_EDITING_PAGE, value: false }));
}

function* updateRentalObjectAllBasicSettingsWorker(
  action: PayloadAction<UpdateRentalObjectAllBasicSettingsPayload>
) {
  yield put(setLoadersData({ type: LoaderTypes.RENTAL_OBJECT_EDITING_PAGE, value: true }));

  const payload = action.payload;
  const workersList = [];

  payload.info && workersList.push(updateRentalObjectWorker(updateRentalObject(payload.info)));
  payload.images &&
    workersList.push(updateRentalObjectImagesWorker(updateRentalObjectImages(payload.images)));

  yield all(workersList);

  yield put(setLoadersData({ type: LoaderTypes.RENTAL_OBJECT_EDITING_PAGE, value: false }));
}

function* updateRentalObjectWorker(action: PayloadAction<UpdateRentalObjectPayload>) {
  const { id, data } = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<CurrentRentalObject, ErrorResponseData> = yield callCheckingAuth(
    API.updateRentalObjectRequest,
    id,
    data
  );

  if (responseData && ok) {
    yield put(setCurrentRentalObject(responseData));
    yield data.features && put(setRentalObjectFeatures(data.features));
    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Изменения сохранены",
        code: 200,
      })
    );
  } else {
    if (status) {
      if (responseData && !ok && status === 400) {
        yield put(setErrorResponeData(responseData));
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка сохранения",
            code: status,
          })
        );
      }
    }
  }
}

export default function* rentalObjectSaga() {
  yield all([
    takeLatest(getRentalObjectTypes, getRentalObjectTypesWorker),
    takeLatest(createRentalObject, createRentalObjectWorker),
    takeLatest(getRentalObjectList, getRentalObjectListWorker),
    takeEvery(removeRentalObject, removeRentalObjectWorker),
    takeLatest(getCurrentRentalObject, getCurrentRentalObjectWorker),
    takeLatest(getRentalObjectTabsData, getRentalObjectTabsDataWorker),
    takeLatest(updateRentalObject, updateRentalObjectWorker),
    takeLatest(getCurrentRentalImages, getCurrentRentalImagesWorker),
    takeLatest(updateRentalObjectImages, updateRentalObjectImagesWorker),
    takeLatest(updateRentalObjectAllBasicSettings, updateRentalObjectAllBasicSettingsWorker),
  ]);
}
