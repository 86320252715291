
import { Link } from "react-router-dom";
import { RoutesList } from "../../App";
import Button from "../../components/Button";
import { ButtonType } from "../../utils/@globalTypes";

import styles from "./NotFound.module.scss"

const NotFound = () => {
  
  return(
    <div className={styles.container}>
      <div className={styles.titleLine}>
        <h1 className={styles.title}>
          404
        </h1>
      </div>
      <div className={styles.middleLine}>
        <h2 className={styles.middleTitle}>
          Страница не найдена
        </h2>
        <div className={styles.socket}>

        </div>
      </div>
      <div className={styles.content}>
        <p>Нам очень жаль. Запрошенная Вами страница не может быть найдена.</p>
        <p>Пожалуйста, вернитесь на главную страницу.</p>
      </div>
      <div className={styles.btnWrap}>
        <Link to={RoutesList.checkerboard.fullPath} >
          <Button 
            title="Вернуться"
            type={ButtonType.PRIMARY}            
          />
        </Link>
      </div>
    </div>
  )
}

export default NotFound;