import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./RoomCard.module.scss";
import SelectComponent from "../SelectComponent";
import SelectComponentV2 from "../SelectComponentV2";
import Button from "../Button";
import { ButtonType, FieldTypes, InputType } from "../../utils/@globalTypes";
import { useSelector } from "react-redux";
import { RoomListWithStatusType, RoomListWithStatusTypeList } from "./@types";
import { BedType } from "../../redux/types/rentalObjectTypes";
import { banDoubleSpace, setFieldErrorText } from "../../utils/functions";
import Input from "../Input";
import { ROOM_NAME_REQ } from "../../utils/constants";
import { RoomSelectors } from "../../redux/reducers/roomSlice";

type RoomCardProps = {
  isSave: Boolean;
  room: RoomListWithStatusType;
  roomIndex: number;
  setRoomData: React.Dispatch<React.SetStateAction<RoomListWithStatusTypeList>>;
  setIsError: React.Dispatch<React.SetStateAction<boolean[]>>;
};

const BED_COUNT = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

const BED_TYPES: any = {
  double_bed: "Двуспальная кровать",
  single_bed: "Односпальная кровать",
  children_bed: "Детская кровать",
  extra_sleeping_place: "Доп. спальное место",
};

const RoomCard: FC<RoomCardProps> = ({ isSave, room, roomIndex, setRoomData, setIsError }) => {
  const roomTypes = useSelector(RoomSelectors.getRoomTypes);

  const [cardRoomType, setCardRoomType] = useState("");
  const [cardRoomName, setCardRoomName] = useState("");

  const [cardRoomTypeError, setCardRoomTypeError] = useState("");
  const [cardRoomNameError, setCardRoomNameError] = useState("");

  const [cardRoomTypeTouched, setCardRoomTypeTouched] = useState(false);
  const [cardRoomNameTouched, setCardRoomNameTouched] = useState(false);

  const [isDelete, setIsDelete] = useState(false);

  const [beds, setBeds] = useState<BedType>({
    double_bed: 0,
    single_bed: 0,
    children_bed: 0,
    extra_sleeping_place: 0,
  });

  const setBedCount = (bedType: string) => (count: string) => {
    setBeds((prevState) => ({
      ...prevState,
      [bedType]: +count,
    }));
  };

  useEffect(() => {
    if (room) {
      setCardRoomType(room.room.type);
      setBeds(room.room.beds);
      setCardRoomName(room.room.name);
    }
  }, [room]);

  // useEffect(() => {
  //   console.log(beds);
  // }, [beds]);

  useEffect(() => {
    setIsError((prevState) => {
      if (prevState.length >= roomIndex + 1) {
        return prevState.map((item, index) =>
          roomIndex === index ? (!!cardRoomNameError || !!cardRoomTypeError) && !isDelete : item
        );
      } else {
        return [...prevState, (!!cardRoomNameError || !!cardRoomTypeError) && !isDelete];
      }
    });
  }, [cardRoomNameError, cardRoomTypeError, roomIndex, isDelete]);

  useEffect(() => {
    if (isSave) {
      setCardRoomTypeTouched(true);
      setCardRoomNameTouched(true);

      setRoomData((prevState) => [
        ...prevState,
        {
          isValid: !(!!cardRoomNameError || !!cardRoomTypeError),
          isNew: room.isNew,
          isDelete: isDelete,
          isUpdate:
            JSON.stringify(room.room) !==
            JSON.stringify({
              id: room.room.id,
              type: cardRoomType,
              beds: beds,
              name: cardRoomName,
              rental_object: room.room.rental_object,
            }),
          room: {
            id: room.room.id,
            type: cardRoomType,
            beds: beds,
            name: cardRoomName,
            rental_object: room.room.rental_object,
          },
        },
      ]);
    }
  }, [
    isSave,
    room,
    cardRoomType,
    cardRoomName,
    beds,
    setRoomData,
    cardRoomNameError,
    cardRoomTypeError,
    isDelete,
  ]);

  useEffect(() => {
    setFieldErrorText(
      cardRoomTypeTouched,
      cardRoomType,
      setCardRoomTypeError,
      FieldTypes.DEFAULT,
      true
    );
  }, [cardRoomTypeTouched, cardRoomType]);

  useEffect(() => {
    setFieldErrorText(
      cardRoomNameTouched,
      cardRoomName,
      setCardRoomNameError,
      FieldTypes.ROOM_NAME,
      false
    );
  }, [cardRoomNameTouched, cardRoomName]);

  return !isDelete ? (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>{cardRoomName || "Комната"}</h4>
      <div className={styles.contentWrapper}>
        <div className={styles.inputContainer}>
          <SelectComponent
            title="Вид комнаты"
            optionsList={roomTypes}
            currentValue={cardRoomType}
            errText={cardRoomTypeError}
            onBlur={setCardRoomTypeTouched}
            setSelecValue={setCardRoomType}
            isLoading={roomTypes.length === 0}
          />
          <Input
            value={cardRoomName}
            title="Название комнаты (опционально)"
            placeholder="Введите название"
            type={InputType.TEXT}
            onChange={setCardRoomName}
            errText={cardRoomNameError}
            onBlur={setCardRoomNameTouched}
            requirementsText={ROOM_NAME_REQ}
            customBanSymbols={banDoubleSpace}
          />
        </div>
        <div className={styles.bedsListWrapper}>
          {Object.entries(beds).map((item) => {
            return (
              <div key={item[0]} className={styles.bedItem}>
                <p className={styles.label}>{BED_TYPES[item[0]]}</p>
                <SelectComponentV2
                  title={
                    item[0] === "extra_sleeping_place" ? "Количество мест" : "Количество кроватей"
                  }
                  placeholder="-"
                  defaultValueId={0}
                  optionsList={BED_COUNT}
                  currentValue={item[1].toString()}
                  setSelecValue={setBedCount(item[0])}
                  classnameTitleContainer={styles.bedCountLabel}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Button
        title="Удалить комнату"
        type={ButtonType.SECONDARY_SMALL}
        onClick={() => setIsDelete(true)}
        wrapperClassName={styles.deleteBtn}
      />
    </div>
  ) : null;
};

export default RoomCard;
