import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageSelectors, setModalWindowData, setPageData } from "../../redux/reducers/pageSlice";
import BookingListWrap from "../../components/BookingListComponents/BookingListWrap";
import { getBookingList, getChessObjects } from "../../redux/reducers/bookingSlice";
import { LoaderTypes } from "../../utils/@globalTypes";
import Loader from "../../components/Loader";
import UnavailableContent from "../../components/UnavailableContent";
import { TariffsSelectors } from "../../redux/reducers/tariffsSlice";

import styles from "./BookingList.module.scss";
import { TarifTypes } from "../../redux/types/@types";

const BookingList = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(PageSelectors.getLoadersData(LoaderTypes.BOOKING_PAGE));
  const current = useSelector(TariffsSelectors.getCurrentTarif);
  useEffect(() => {
    dispatch(
      setPageData({
        pageTitle: "Список броней",
        pageDescription: "Учет броней, оплат и доступности номеров",
      })
    );

    return () => {
      dispatch(setPageData(null));
    };
  }, []);
  useEffect(() => {
    dispatch(getChessObjects({}));
    dispatch(getBookingList({}));
  }, []);
  
  return isLoading 
  ? (
    <Loader />
  ) : current===TarifTypes.FREE? (
    <UnavailableContent/>)
  :(  
    <BookingListWrap/>
  );
};

export default BookingList;