import { all, put, takeLatest, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import API from "../api";
import { setLoadersData, setMessage, setModalWindowData } from "../reducers/pageSlice";
import { LoaderTypes, MessageTypes, ModalWindowTypes } from "../../utils/@globalTypes";
import { ErrorResponseData, ResponseData, TariffsInfoType } from "../types/@types";
import { getTariffsInfo, setPriceList } from "../reducers/tariffsSlice";
import callCheckingAuth from "./callCheckingAuth";

function* getTariffInfoWorker() {
  yield put(setLoadersData({ type: LoaderTypes.TARIFFS_PAGE, value: true }));
  const { ok, data, status }: ApiResponse<ResponseData<TariffsInfoType[]>> = yield callCheckingAuth(
    API.getTariffsInfo,    
  );
    
  if (data && ok) {    
    yield put(setPriceList(data.results));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения тарифов",
          code: status,
        })
      );
    }
  }
  yield put(setLoadersData({ type: LoaderTypes.TARIFFS_PAGE, value: false }));
}

export default function* tariffSaga() {
  yield all([
    takeLatest(getTariffsInfo, getTariffInfoWorker),    
    
  ]);
}