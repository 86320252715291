import { useDispatch } from "react-redux";
import { setModalWindowData } from "../../../redux/reducers/pageSlice";
import { ButtonType, ModalWindowTypes } from "../../../utils/@globalTypes";
import ModalWindow from "../ModalWindow";

import styles from "./TariffWarning.module.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { RoutesList } from "../../../App";
import Button from "../../Button";


type TariffWarning = {
  data:{
    tariffName:string,
    dataExp: string,
    daysRest: number    
  }
}

const TariffWarningWindow = ({data}:TariffWarning) => {
  
  const dispatch = useDispatch();  
  const navigate = useNavigate();

  if (!data){
    dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
    return null
  }   
  const {tariffName, dataExp, daysRest} = data;  
  const dayTitle = daysRest>4? "дней" : daysRest>1? "дня" : "день"  
  const onSubmit = () =>{
    navigate(RoutesList.prices.fullPath)
  }
  
  if(daysRest>0 ){    
    return (    
      <ModalWindow 
        isLoading={false} 
        title={"Уважаемый пользователь!"}
        btnTitle="Перейти к тарифам"
        onSubmit={onSubmit}      
      >     
        <div>
          <div className={styles.note}>
            <p className={styles.stroke}>
              Период активации Вашего тарифного плана 
              <span className={styles.bold}> "{tariffName}" </span> 
               истекает  
              <span className={styles.bold}> {moment(dataExp).format("DD MMMM YYYY")}. </span>           
              Рекомендуем Вам своевременно произвести оплату для бесперебойной работы. 
            </p>
            <p className={styles.stroke}>В случае отсутствия оплаты Ваш тариф будет переключен на 
            <span className={styles.bold}> "Бесплатный" </span> 
              через 
              <span className={styles.bold}> {daysRest} </span> {dayTitle}.</p>
          </div>        
        </div>
        
      </ModalWindow>
    );
  }
  return (    
    <ModalWindow 
      isLoading={false} 
      title={"Уважаемый пользователь!"}
      btnTitle="Перейти к тарифам"
      windowClassname={styles.window}
      isCloseOnOverlay = {false}     
      customBtns
      hideCross
    >          
      <div className={styles.note}>
        <p>Период активации Вашего тарифного плана "{tariffName}" истек.</p>
        <p>Для продолжения работы Вам необходимо выбрать тарифный план. </p>          
      </div>        
      
      <div className={styles.btnWrap}>          
        <Button 
          title={"Выбрать тариф"}
          type={ButtonType.PRIMARY}  
          onClick={onSubmit}          
        />          
      </div>
    </ModalWindow>
  );
};

export default TariffWarningWindow;