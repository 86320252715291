import { useEffect } from "react";
import { BookingFormType, FormBookingType} from "../../../redux/types/bookingTypes";
import { InputModeType, InputType, OptionsListType } from "../../../utils/@globalTypes";
import { 
  FIRST_NAME_REQ, 
  LAST_NAME_REQ, 
  MIDDLE_NAME_REQ, 
  } from "../../../utils/constants";
import Input from "../../Input";
import BookingSectionTitle from "../BookingSectionTitle";
import BookingLineTitle from "../BookingLineTitle";
import RadioButton from "../../RadioButton";
import SelectComponent from "../../SelectComponent";
import { useSelector } from "react-redux";
import { MainObjectSelectors } from "../../../redux/reducers/mainObjectSlice";
import Calendar from "../../Calendar";

import styles from "./BookingPersonBlock.module.scss";
import { FormikProps, useFormikContext } from "formik";


type PersonblockProps ={
  windowType:BookingFormType,
  formik:FormikProps<FormBookingType>,    
}

const BookingPersonBlock = ({
  windowType=BookingFormType.view, 
  formik  
}:PersonblockProps) => {
  const {setFieldValue} = useFormikContext()
  const {handleChange, values, errors, setFieldTouched, validateField, touched} = formik;

  const countriesList:OptionsListType = useSelector(MainObjectSelectors.getCountriesOptions)
      useEffect(() => {
      countriesList[15]={ value: "ot", label: "Другое" };  
      }, []);  
    
  const RadioButtonList = ["Мужской", "Женский"];    
  
  useEffect(() => {
    if(touched.last_name){
      validateField("last_name")
    };
  }, [touched, values]);

  useEffect(() => {
    if(touched.first_name){
      validateField("first_name")
    };
  }, [touched, values]);

  useEffect(() => {
    if(touched.second_name&&values.second_name){
      if(values?.second_name.length>0){
        validateField("second_name")
      } else formik.setFieldError("second_name", "")
    };
  }, [touched, values]);

  useEffect(() => {
    if(touched.nationality){
      validateField("nationality")
    };
  }, [touched, values]);
 
  return (
    <div className={styles.wrap}>
      <BookingSectionTitle title="Личная информация"/>
      <div className={styles.container}>
        <div className={styles.leftColumn}>
        
        <Input
            id="last_name"
            value={values.last_name}
            title={windowType===BookingFormType.view ? "Фамилия" : "Фамилия*"}
            disabled={windowType===BookingFormType.view ? true : false}
            name= "last_name"
            placeholder= "Введите фамилию"
            type={InputType.TEXT}
            inputMode={InputModeType.TEXT}            
            errText={windowType===BookingFormType.view ? undefined: errors.last_name?errors.last_name:undefined}
            className= {styles.booking}
            containerClassName={styles.inputСontainer}
            onChange={handleChange("last_name")}
            requirementsText={windowType===BookingFormType.view ? undefined: LAST_NAME_REQ}
            isBooking={true}
            onBlur={() => setFieldTouched("last_name")}          
            banSymbols={/\s/g}
            autoComplete="family-name"           
          />
        <Input
            id="first_name"
            value={values.first_name}
            title={windowType===BookingFormType.view ? "Имя" : "Имя*"}
            disabled={windowType===BookingFormType.view ? true: false }
            name= "first_name"
            placeholder= "Введите имя"
            type={InputType.TEXT}
            inputMode={InputModeType.TEXT}            
            errText={windowType===BookingFormType.view ?undefined: errors.first_name }
            className= {styles.booking}
            containerClassName={styles.inputСontainer}
            onChange={handleChange("first_name")}
            onBlur={() => setFieldTouched("first_name")} 
            requirementsText={windowType===BookingFormType.view ? undefined: FIRST_NAME_REQ }            
            isBooking={true}
            banSymbols={/\s/g}
            autoComplete="given-name"
          />
          <Input
            id="second_name"
            value={values.second_name?values.second_name:""}
            title="Отчество"
            disabled={windowType===BookingFormType.view ? true: false }
            name="second_name"
            placeholder= {windowType===BookingFormType.view ?"-": "Введите отчество" }
            type={InputType.TEXT}
            inputMode={InputModeType.TEXT}            
            errText={windowType===BookingFormType.view ? undefined: errors.second_name }
            containerClassName={styles.inputСontainer}
            className= {styles.booking}
            onChange={handleChange("second_name")}
            onBlur={() => setFieldTouched("second_name")} 
            isBooking={true}            
            requirementsText={windowType===BookingFormType.view ?undefined: MIDDLE_NAME_REQ }
            banSymbols={/\s/g}
            autoComplete="additional-name"
          />
        </div>
        <div className={styles.rightColumn}>
          <SelectComponent
            id="nationality"
            name="nationality"
            title={windowType===BookingFormType.view ? "Гражданство" : "Гражданство*"}
            optionsList={countriesList}
            currentValue={values.nationality}
            placeholder="Укажите гражданство"
            // isSearchable={windowType===BookingFormType.view ? true: false }
            isDisabled={windowType===BookingFormType.view ? true: false }
            setSelecValue={handleChange("nationality")}
            errText={windowType===BookingFormType.view ? undefined: errors.nationality}
            isBooking={true}
            onBlur={() => setFieldTouched("nationality")}            
          />
          <div className={styles.line}>
            <BookingLineTitle title="Дата рождения"/>
            <Calendar
              name="birth_day"
              id="birth_day"
              disabled={windowType===BookingFormType.view ? true: false }
              placeholderText={windowType===BookingFormType.view ? "-": "Введите дату рождения" }
              selectedDate={values.birth_day}
              onChange={(val) => 
                setFieldValue("birth_day", val)
                }
              showYearDropdown={true}
              showMonthDropdown={true}
              isSmall={true}    
              maxDate={new Date()}    
              excludeDates={[new Date()]}   
              onBlur={() => setFieldTouched("birth_day")}              
            />
          </div>
          <div className={styles.line}>
            <BookingLineTitle title="Пол"/>
            <RadioButton
              id="sex"
              name="sex"
              btnsList={RadioButtonList}
              onClick={(val)=> setFieldValue("sex", RadioButtonList[val])}
              // activeTab={values.sex}
              activeTab={RadioButtonList.findIndex(item=> item===values.sex)}
              size="booking"
              isDisabled={windowType===BookingFormType.view ? true: false }
            />
          </div>
        </div>
      </div>
    </div>
    )
}

export default BookingPersonBlock ;