import { useEffect, useState } from "react";
import { TarifTypes } from "../../../redux/types/@types";
import { useDispatch, useSelector } from "react-redux";
import { setModalWindowData } from "../../../redux/reducers/pageSlice";
import { TariffsSelectors } from "../../../redux/reducers/tariffsSlice";
import { ModalWindowTypes } from "../../../utils/@globalTypes";
import ModalWindow from "../ModalWindow";

import styles from "./TariffActivationWindow.module.scss";
import { CURRENCY } from "../../../utils/constants";


type TariffActivation = {
  data:{
    code:TarifTypes,
    period: 12 | 6 | 1,
    monthlyPrice: string,
    halfYearPrice:string,
    yearPrice: string,
    label:string,
    discont:{
      halfYear: number;
      year: number;
    },
    isCurrent:boolean
  }
}

const TariffActivationWindow = ({data}:TariffActivation) => {
  
  const dispatch = useDispatch();  
  const [periodChoice, setPeriodChoice] = useState<string>("");
  const [paymentType, setPaymentType] = useState<string>("1");
  
  useEffect(()=>{ 
    if (data?.period){
      setPeriodChoice(data.period.toString())
    }
  },[])

  if (!data){
    dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
    return null
  } 
  
  const {code, period, monthlyPrice, halfYearPrice, yearPrice, label, discont, isCurrent} = data;  
  const handlerSave = () => {
    const text = periodChoice==="1"? " месяц" : " месяцев"
    const cost = periodChoice==="1"
    ?monthlyPrice
    :periodChoice==="6"
    ?halfYearPrice
    :yearPrice
    alert(`Ваша заявка на тарифный план "${label}" на ${periodChoice} ${text} за ${cost} руб. зафиксирована`)
    dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
  }
  const handlerFree = () => {
    alert(`Ваша заявка на тарифный план "${label}" зафиксирована`)
    dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
  }

  if(code===TarifTypes.FREE){
    const note = isCurrent
    ? `Ваш текущий тарифный план "${label}"` 
    : `Вы можете активировать тарифный план "${label}" на неопределенный срок`
    return (
      <ModalWindow 
        isLoading={false} 
        title={`Тарифный план "${label}"`}
        btnTitle="Отправить заявку"
        containerClassname="tariff"
        onSubmit={handlerFree}
        customBtns = {isCurrent}
      >     
        <div>
          <h4 className={styles.note}>{note}</h4>
          <div>В любое время Вы сможете перейти на другой тарифный план</div>
        </div>
        
      </ModalWindow>
    )
  }
  
  return (
    
    <ModalWindow 
      isLoading={false} 
      title={`Тарифный план "${label}"`}
      btnTitle="Подтвердить"
      containerClassname="tariff"
      onSubmit={handlerSave}
    >     
      <div>
        <div className={styles.note}>Выберите период подписки </div>
        <div className={styles.btnGroup}>
          <label>
            <input type="radio" value="1" checked={periodChoice === "1"} onChange={()=>setPeriodChoice("1")} />
              на 1 месяц за {monthlyPrice} {CURRENCY}
          </label>
          <label>
            <input type="radio" value="6" checked={periodChoice === "6"} onChange={()=>setPeriodChoice("6")} />
            <span>на 6 месяцев за {halfYearPrice} {CURRENCY} (экономия {discont.halfYear}%)</span>
          </label>
          
          <label>
            <input type="radio" value="12" checked={periodChoice === "12"} onChange={()=>setPeriodChoice("12")} />
              на 12 месяцев за {yearPrice} {CURRENCY} (экономия {discont.year}%)
          </label>
        </div>
        <h4 className={styles.note}>Выберите способ оплаты </h4>
        <div className={styles.btnGroup}>
          <label>
            <input type="radio" value="1" checked={paymentType === "1"} onChange={()=>setPaymentType("1")} />
              банковской картой онлайн
          </label>
          <label>
            <input type="radio" value="2" checked={paymentType === "2"} onChange={()=>setPaymentType("2")} />
            <span>безналичный расчет по счет-фактуре</span>
          </label>         
        </div>
      </div>
      
    </ModalWindow>
  );
};

export default TariffActivationWindow;