import React, { FC, ReactNode, useEffect } from "react";
import moment from "moment";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { TariffsSelectors } from "../../redux/reducers/tariffsSlice";
import { tariffs } from "../../utils/tarifDatas";

import styles from "./PagesContainer.module.scss";
import { setModalWindowData } from "../../redux/reducers/pageSlice";
import { ModalWindowTypes } from "../../utils/@globalTypes";
import { WARNING } from "../../utils/constants";

type PagesContainerProps = {
  children: ReactNode;
};

const PagesContainer: FC<PagesContainerProps> = ({ children }) => {
  const dispatch = useDispatch();
  
  const currentTariff = useSelector(TariffsSelectors.getCurrentTarif)
  const tariffName = tariffs.find(item=> item.code===currentTariff)?.label  
  const tariffExp = useSelector(TariffsSelectors.getTariffExpDate)
  
  useEffect(() => {
    if(tariffExp){
      const today = moment().startOf("day")
      const exp = moment(tariffExp).startOf("day")    
      const diff = exp.diff(today, "days")      
      const todayFormat = moment(today).format("DD MMMM YYYY")
      const memory = localStorage.getItem(WARNING)
      
      if(diff<8 && diff>0 && (todayFormat!==memory)){        
        localStorage.setItem(WARNING, todayFormat)
        dispatch(setModalWindowData({
          type: ModalWindowTypes.TARIFF_WARNING, 
          data:{
            tariffName:tariffName,
            dataExp:tariffExp,
            daysRest:diff
          }}))   
      }
      if(diff===0){        
        localStorage.removeItem(WARNING)
        dispatch(setModalWindowData({
          type: ModalWindowTypes.TARIFF_WARNING, 
          data:{
            tariffName:tariffName,
            dataExp:tariffExp,
            daysRest:diff
          }}))   
      }
    }
  }, [tariffExp]);
  
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <Sidebar />
      </div>
      <div className={styles.rightWrapper}>
        <Header />
        <div className={styles.contentWrapper}>{children}</div>
      </div>
    </div>
  );
};

export default PagesContainer;
