import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {addDays} from 'date-fns';
import moment from "moment";
import { Formik, Form } from  'formik' ; 
import { BookingFormType, BookingType, FormBookingType, UpdateBookingType } from "../../../redux/types/bookingTypes";
import { removeBooking, updateCurrentBooking } from "../../../redux/reducers/bookingSlice";
import BookingContactBlock from "../BookingContactBlock";
import BookingHeader from "../BookingHeader";
import BookingObjectBlock from "../BookingObjectBlock";
import BookingPersonBlock from "../BookingPersonBlock";
import BookingCommentBlock from "../BookingCommentBlock";

import styles from "./BookingContainer.module.scss";
import { setModalWindowData } from "../../../redux/reducers/pageSlice";
import { ButtonType, ModalWindowTypes } from "../../../utils/@globalTypes";
import Button from "../../Button";
import useValidationSchema from '../validationSchema';
import ScrollToError from "../ScrollToError";
import { formatDate, getSex } from "../functions";

type BookingContainerProps={
  booking:BookingType,
  type:BookingFormType
}

const BookingContainer = ({booking, type}:BookingContainerProps) => {
  const dispatch = useDispatch();
  const validationSchema = useValidationSchema()
  const {check_out_date, id} = booking;

  const [isEdit, setIsEdit] = useState(false);   // переключение формы между просмотром и редактированием
  const windowType=isEdit?BookingFormType.edit:BookingFormType.view;
  
  const [isReadyToDelete, setIsReadyToDelete] = useState(false);  //управление активностью кнопки "Удалить"  
  const sexValue = booking.sex==="mal"?"Мужской":booking.sex==="fem"?"Женский":""

  const initialValues:FormBookingType={
    status: booking.status,      //№1 в форме на мобиле
    rental_object: booking.rental_object,   
    check_in_date: new Date(booking.check_in_date),
    check_out_date: new Date(booking.check_out_date),    
    check_in_time: booking.check_in_time,
    check_out_time: booking.check_out_time,
    adult: booking.adult,
    children:booking.children,
    has_animals: booking.has_animals,  
    animals_info:booking.animals_info||"",
    total: booking.total,
    paid: booking.paid,
    rest: (Number(booking.total)-Number(booking.paid)).toString(),
    last_name: booking.last_name, 
    first_name: booking.first_name,    
    second_name:booking.second_name||"",
    nationality: booking.nationality,
    birth_day:booking.birth_day?new Date(booking.birth_day):undefined,
    sex:sexValue,
    email: booking.email, 
    phone: {
      country_code: booking.phone.country_code,
      phone_code: booking.phone.phone_code,
      phone_number: booking.phone.phone_number
    },   
    telegram:booking.telegram||"",
    comment:booking.comment||"",
  }  
 
  const isLast = moment(check_out_date).startOf('day').unix()<moment(new Date()).startOf('day').unix() // является ли бронь прошедшей

  useEffect(()=> {  //если бронь прошедшая - сразу можно удалять
    if(isLast){
      setIsReadyToDelete(true)
    }    
  }, [isLast]);

  useEffect(()=> {   //переключение в режим редактирования
    if(type===BookingFormType.edit){      
      setIsEdit(true)
    }    
  }, [type]);
    
  useEffect(()=> {  //если статус брони "отменена" - можно удалять
    if(booking.status==="cld"){
      setIsReadyToDelete(true)
    }    
  }, [booking.status]);

  const handleClickEdit = ()=>{setIsEdit(true)};  //переключение формы в режим редактирования

  const handleClickDelete = ()=>{
    dispatch(removeBooking(id));    
  };
  const close = () => {
    dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE })); 
  } 
 
  return (
    <Formik 
    initialValues={initialValues}
    validationSchema={validationSchema}
    validateOnBlur = {false}          
    validateOnChange ={false}   
    validateOnMount ={false}            
    onSubmit={(values)=>{
      
      const updateBooking:UpdateBookingType = {};
      if(booking.rental_object!==values.rental_object){
         updateBooking.rental_object = values.rental_object;
      }
      if(booking.status!==values.status){
         updateBooking.status = values.status;
      }
      if(booking.check_in_date!==formatDate(values.check_in_date)){
         updateBooking.check_in_date = formatDate(values.check_in_date);
      }
      if(booking.check_out_date!==formatDate(values.check_out_date)){
         updateBooking.check_out_date = formatDate(values.check_out_date);
      }
      if(booking.check_in_time!==values.check_in_time){
         updateBooking.check_in_time = values.check_in_time;
      }
      if(booking.check_out_time!==values.check_out_time){
         updateBooking.check_out_time = values.check_out_time;
      }       
      if(booking.adult!==values.adult){
         updateBooking.adult = values.adult;
      }       
      if(JSON.stringify(booking.children)!==JSON.stringify(values.children)){
         updateBooking.children = values.children;
      }       
      if(booking.has_animals!==values.has_animals){
        updateBooking.has_animals = values.has_animals;
      } 
      if(booking.animals_info!==values.animals_info){
        updateBooking.animals_info = values.animals_info;
      } 
      if(booking.total!==values.total){
        updateBooking.total = values.total; 
      }     
      if(booking.paid!==values.paid){
        updateBooking.paid = values.paid;
      } 
      if(booking.last_name!==values.last_name){
        updateBooking.last_name = values.last_name;
      } 
      if(booking.first_name!==values.first_name){
        updateBooking.first_name = values.first_name;
      } 
      if(booking.second_name!==values.second_name){
        updateBooking.second_name = values.second_name;
      } 
      if(booking.nationality!==values.nationality){
        updateBooking.nationality = values.nationality;
      } 
      if(values.birth_day&&booking.birth_day!==formatDate(values.birth_day)){
        updateBooking.birth_day = formatDate(values.birth_day);
      }      
      if(values.sex){
        const valSex = getSex(values.sex)
        if (booking.sex!==valSex)updateBooking.sex = valSex;
      }    
      if(booking.email!==values.email){
        updateBooking.email = values.email;
      }    
      if(JSON.stringify(booking.phone)!==JSON.stringify(values.phone)){
        updateBooking.phone = values.phone;
      }    
      if(booking.telegram!==values.telegram){
        updateBooking.telegram = values.telegram;
      } 
      if(booking.comment!==values.comment){
        updateBooking.comment = values.comment;
      }      
    try {
      dispatch(updateCurrentBooking({id:id, data:updateBooking}));     
    } catch (error) {
      console.error('Ошибка отправки:', error);
    } 
    }}>
      {(formik) => (
      <Form  >
      <BookingHeader 
        windowType ={windowType}
        handleClickEdit={handleClickEdit}
        handleClickDelete = {handleClickDelete} 
        isLast = {isLast}
        isReadyToDelete={isReadyToDelete}        
        formik={formik}
        windowClose={close}   
      />
        <main className={styles.mainContainer}> 
          <BookingObjectBlock 
            windowType ={windowType} 
            formik={formik}     
            isLast = {isLast}
            booking={booking}                       
          />
          <BookingPersonBlock 
            windowType ={windowType} 
            formik={formik}                          
          />
          <BookingContactBlock 
            windowType ={windowType} 
            formik={formik}                        
          />
          <BookingCommentBlock
            windowType ={windowType} 
            formik={formik}                           
          />
        <div className={styles.btnSave}>
          <Button 
            title="Сохранить" 
            disabled={!formik.dirty}
            type={ButtonType.PRIMARY_SMALL} 
            actionType="submit"                        
          />  
        </div>
      </main>
      <ScrollToError />                    
    </Form>
  )}
</Formik>

  )
}

export default BookingContainer ;