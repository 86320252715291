import React, { FC, useState, useRef } from "react";
import styles from "./UserName.module.scss";
import { ArrowIcon, ProfileIcon } from "../../assets/icons";
import { useClickOutside } from "../../utils/functions";

type UserNameProps = {
  username: string;
  jobTitle: string;
  avatar?: string;
  dropdownList: {
    title: string;
    onClick: () => void;
  }[];
};

const UserName: FC<UserNameProps> = ({ username, jobTitle, avatar, dropdownList }) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const menuRef = useRef(null);
  const btnRef = useRef(null);

  const onUserNameClick = () => {
    setIsDropdownOpened(!isDropdownOpened);
  };

  useClickOutside(menuRef, btnRef, () => setIsDropdownOpened(false));

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>{avatar ? <img src={avatar} /> : <ProfileIcon />}</div>
        <div>
          <p className={styles.username}>{username}</p>
          <p className={styles.jobTitle}>{jobTitle}</p>
        </div>
        <div className={styles.arrowBtn} ref={btnRef} onClick={onUserNameClick}>
          <ArrowIcon fill="#1855B7" />
        </div>
      </div>
      {isDropdownOpened && (
        <div className={styles.dropdownMenu} ref={menuRef}>
          {dropdownList.map(({ title, onClick }, index) => {
            const clickFunc = ()=>{
              onClick();
              setIsDropdownOpened(false)
            }
            return (
              <div className={styles.button} key={title + index} onClick={clickFunc}>
                {title}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UserName;
