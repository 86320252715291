import React from "react";

export const InfoIcon = ({ width = "28", height = "28", fill = "#0F2664" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="26" height="26" rx="13" stroke={fill} strokeWidth="2"/>
      <path d="M12.956 20V11.52H14.492V20H12.956ZM13.724 9.888C13.4253 9.888 13.1747 9.792 12.972 9.6C12.78 9.408 12.684 9.17333 12.684 8.896C12.684 8.608 12.78 8.368 12.972 8.176C13.1747 7.984 13.4253 7.888 13.724 7.888C14.0227 7.888 14.268 7.984 14.46 8.176C14.6627 8.35733 14.764 8.58667 14.764 8.864C14.764 9.152 14.668 9.39733 14.476 9.6C14.284 9.792 14.0333 9.888 13.724 9.888Z" fill={fill}/>
      <path d="M12.956 20V11.52H14.492V20H12.956ZM13.724 9.888C13.4253 9.888 13.1747 9.792 12.972 9.6C12.78 9.408 12.684 9.17333 12.684 8.896C12.684 8.608 12.78 8.368 12.972 8.176C13.1747 7.984 13.4253 7.888 13.724 7.888C14.0227 7.888 14.268 7.984 14.46 8.176C14.6627 8.35733 14.764 8.58667 14.764 8.864C14.764 9.152 14.668 9.39733 14.476 9.6C14.284 9.792 14.0333 9.888 13.724 9.888Z" fill={fill}/>
      <path d="M12.956 20V11.52H14.492V20H12.956ZM13.724 9.888C13.4253 9.888 13.1747 9.792 12.972 9.6C12.78 9.408 12.684 9.17333 12.684 8.896C12.684 8.608 12.78 8.368 12.972 8.176C13.1747 7.984 13.4253 7.888 13.724 7.888C14.0227 7.888 14.268 7.984 14.46 8.176C14.6627 8.35733 14.764 8.58667 14.764 8.864C14.764 9.152 14.668 9.39733 14.476 9.6C14.284 9.792 14.0333 9.888 13.724 9.888Z" fill={fill}/>
    </svg>

  );
};
