import { useSelector } from "react-redux";
import { TariffsSelectors } from "../../../redux/reducers/tariffsSlice";
import { tariffs } from "../../../utils/tarifDatas";
import { TarifTypes } from "../../../redux/types/@types";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import { RoutesList } from "../../../App";
import { ButtonType } from "../../../utils/@globalTypes";

import styles from "./TariffInfo.module.scss";
import moment from "moment";

const TariffInfo = () => {
  
  const currentTariff = useSelector(TariffsSelectors.getCurrentTarif)
  const tariffName = tariffs.find(item=> item.code===currentTariff)?.label
  const tariffExp = useSelector(TariffsSelectors.getTariffExpDate)
  const displayExpDate = currentTariff===TarifTypes.FREE
  ? "бессрочно" 
  : tariffExp
  ? `до ${moment(tariffExp).format("DD MMMM YYYY")}` 
  : "бессрочно"

  return(
    <div className={styles.container}>
      <h3 className={styles.title}>
        Действующий тариф
      </h3>
      <p className={styles.content}>
        В данный момент вы используете тариф "{tariffName}"
      </p>
      <p className={styles.content}>
        Срок действия: <b className={styles.bold}>{displayExpDate}</b>
      </p>
      <div className={styles.btnWrap}>
          <Link to={RoutesList.prices.fullPath} >
            <Button 
              title={"Перейти к тарифам"}
              type={ButtonType.PRIMARY}            
            />
          </Link>
        </div>
    </div>
  )
}

export default  TariffInfo