
import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import mainObjectSaga from "./mainObjectSaga";
import rentalObjectSaga from "./rentalObjectSaga";
import userSaga from "./userSaga";
import bookingSaga from "./bookingSaga";
import roomSaga from "./roomSaga";
import pricesSaga from "./pricesSaga";
import bookingSettingsSaga from "./bookingSettingsSaga";
import tariffSaga from "./../sagas/tariffSaga";


export default function* rootSaga() {
  yield all([
    authSaga(),
    mainObjectSaga(),
    rentalObjectSaga(),
    userSaga(),
    bookingSaga(),
    roomSaga(),
    pricesSaga(),
    bookingSettingsSaga(),
    tariffSaga()
  ]);
}
