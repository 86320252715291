import React from "react";

export const MarkerIcon = ({ width = "16", height = "16", fill = "#42AC4D" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M2.16797 8L6.05795 11.5L13.8346 4.5" 
        stroke={fill} 
        strokeWidth="1.75" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
};