import React from "react";

export const CopyIcon = ({ width = "40", height = "40", fill = "#8692A6" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7261 10.7842C13.7261 10.0041 14.036 9.25605 14.5876 8.70447C15.1391 8.15289 15.8872 7.84302 16.6673 7.84302H28.432C29.212 7.84302 29.9601 8.15289 30.5117 8.70447C31.0633 9.25605 31.3732 10.0041 31.3732 10.7842V22.5489C31.3732 23.3289 31.0633 24.077 30.5117 24.6286C29.9601 25.1802 29.212 25.4901 28.432 25.4901H16.6673C15.8872 25.4901 15.1391 25.1802 14.5876 24.6286C14.036 24.077 13.7261 23.3289 13.7261 22.5489V10.7842ZM16.6673 9.31361C16.2773 9.31361 15.9032 9.46854 15.6274 9.74433C15.3516 10.0201 15.1967 10.3942 15.1967 10.7842V22.5489C15.1967 22.9389 15.3516 23.313 15.6274 23.5888C15.9032 23.8646 16.2773 24.0195 16.6673 24.0195H28.432C28.822 24.0195 29.1961 23.8646 29.4718 23.5888C29.7476 23.313 29.9026 22.9389 29.9026 22.5489V10.7842C29.9026 10.3942 29.7476 10.0201 29.4718 9.74433C29.1961 9.46854 28.822 9.31361 28.432 9.31361H16.6673ZM10.7849 15.196C10.3949 15.196 10.0209 15.3509 9.74506 15.6267C9.46927 15.9025 9.31434 16.2765 9.31434 16.6665V28.4313C9.31434 28.8213 9.46927 29.1953 9.74506 29.4711C10.0209 29.7469 10.3949 29.9018 10.7849 29.9018H22.5496C22.9397 29.9018 23.3137 29.7469 23.5895 29.4711C23.8653 29.1953 24.0202 28.8213 24.0202 28.4313V26.9607H25.4908V28.4313C25.4908 29.2113 25.1809 29.9594 24.6294 30.511C24.0778 31.0626 23.3297 31.3724 22.5496 31.3724H10.7849C10.0049 31.3724 9.25678 31.0626 8.7052 30.511C8.15362 29.9594 7.84375 29.2113 7.84375 28.4313V16.6665C7.84375 15.8865 8.15362 15.1384 8.7052 14.5868C9.25678 14.0352 10.0049 13.7254 10.7849 13.7254H12.2555V15.196H10.7849Z"
        fill={fill}
      />
    </svg>
  );
};
