import React, { FC } from "react";
import classNames from "classnames";
import styles from "./Allowed.module.scss";
import { CloseSquareIcon, TickSquareIcon } from "../../assets/icons";
import { RentalObjectStatusesEnum } from "../../redux/types/rentalObjectTypes";

type AllowedProps = {
  status: string;
};

const Allowed: FC<AllowedProps> = ({ status }) => {
  const isStatusOk = status === RentalObjectStatusesEnum.AFB;

  return (
    <div
      className={classNames(styles.allowedWrapper, {
        [styles.statusOk]: isStatusOk,
        [styles.statusErr]: !isStatusOk,
      })}
    >
      {isStatusOk ? <TickSquareIcon /> : <CloseSquareIcon />}
      <p className={styles.title}>
        {isStatusOk ? "Доступен" : "Не доступен"}
        <p className={styles.prompt}>Объект{isStatusOk ? "" : " не"} доступен к бронированию</p>
      </p>
    </div>
  );
};

export default Allowed;
