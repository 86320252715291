import React from "react";

export const ShortLogo = ({ width = "40", height = "42", fill = "#ffffff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.5047 39.4811H18.7605L33.0611 8.75781H39.3022L23.2753 41.9602H16.7691L0.742188 8.75781H7.02926L21.5047 39.4811Z" fill={fill}/>
      <path d="M27.3757 20.0837H12.6211L14.2503 16.6874L17.4292 10.0664H22.5677L25.7465 16.6874L27.3757 20.0837Z" fill={fill}/>
      <path d="M23.3525 7.52307H16.6484L20.1189 0.25L23.3525 7.52307Z" fill="#69C931"/>
    </svg>
  );
};
