import React from "react";

export const InstagramIcon = ({ width = "28", height = "28", fill = "#A2BBFF" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M10.22 6H17.78C20.66 6 23 8.34 23 11.22V18.78C23 20.1644 22.45 21.4922 21.4711 22.4711C20.4922 23.45 19.1644 24 17.78 24H10.22C7.34 24 5 21.66 5 18.78V11.22C5 9.83557 5.54996 8.50784 6.5289 7.5289C7.50784 6.54996 8.83557 6 10.22 6ZM10.04 7.8C9.1807 7.8 8.35659 8.14136 7.74897 8.74897C7.14136 9.35659 6.8 10.1807 6.8 11.04V18.96C6.8 20.751 8.249 22.2 10.04 22.2H17.96C18.8193 22.2 19.6434 21.8586 20.251 21.251C20.8586 20.6434 21.2 19.8193 21.2 18.96V11.04C21.2 9.249 19.751 7.8 17.96 7.8H10.04ZM18.725 9.15C19.0234 9.15 19.3095 9.26853 19.5205 9.4795C19.7315 9.69048 19.85 9.97663 19.85 10.275C19.85 10.5734 19.7315 10.8595 19.5205 11.0705C19.3095 11.2815 19.0234 11.4 18.725 11.4C18.4266 11.4 18.1405 11.2815 17.9295 11.0705C17.7185 10.8595 17.6 10.5734 17.6 10.275C17.6 9.97663 17.7185 9.69048 17.9295 9.4795C18.1405 9.26853 18.4266 9.15 18.725 9.15ZM14 10.5C15.1935 10.5 16.3381 10.9741 17.182 11.818C18.0259 12.6619 18.5 13.8065 18.5 15C18.5 16.1935 18.0259 17.3381 17.182 18.182C16.3381 19.0259 15.1935 19.5 14 19.5C12.8065 19.5 11.6619 19.0259 10.818 18.182C9.97411 17.3381 9.5 16.1935 9.5 15C9.5 13.8065 9.97411 12.6619 10.818 11.818C11.6619 10.9741 12.8065 10.5 14 10.5ZM14 12.3C13.2839 12.3 12.5972 12.5845 12.0908 13.0908C11.5845 13.5972 11.3 14.2839 11.3 15C11.3 15.7161 11.5845 16.4028 12.0908 16.9092C12.5972 17.4155 13.2839 17.7 14 17.7C14.7161 17.7 15.4028 17.4155 15.9092 16.9092C16.4155 16.4028 16.7 15.7161 16.7 15C16.7 14.2839 16.4155 13.5972 15.9092 13.0908C15.4028 12.5845 14.7161 12.3 14 12.3Z" 
        fill={fill}
      />
    </svg>
  );
};
