import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import styles from "./BookingSettings.module.scss";
import { ButtonType, OptionsListType, TabsNames } from "../../../../utils/@globalTypes";
import SelectTime from "../../../../components/SelectTime";
import SelectComponentV2 from "../../../../components/SelectComponentV2";
import Button from "../../../../components/Button";
import { RoutesList } from "../../../../App";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BookingSettingsSelectors,
  updateBookingSettings,
} from "../../../../redux/reducers/bookingSettingsSlice";
import { BookingSettingsPayloadData } from "../../../../redux/types/bookingSettingsTypes";

const MIN_DAYS_OPTIONS: OptionsListType = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

const BookingSettings = () => {
  const dispatch = useDispatch();
  const { mainId } = useParams();

  const settings = useSelector(BookingSettingsSelectors.getBookingSettings);

  const [checkIn, setCheckIn] = useState<Date | null>(null);
  const [checkOut, setCheckOut] = useState<Date | null>(null);
  const [highSeason, setHighSeason] = useState("");
  const [lowSeason, setLowSeason] = useState("");

  const [checkInError, setCheckInError] = useState("");

  const onSaveObjectBtnClick = () => {
    if (settings && checkIn && checkOut) {
      const payloadData: BookingSettingsPayloadData = {};

      const { check_in, check_out, min_days_high_season, min_days_low_season } = settings;

      check_in !== checkIn.toLocaleTimeString() &&
        (payloadData.check_in = checkIn.toLocaleTimeString());
      check_out !== checkOut.toLocaleTimeString() &&
        (payloadData.check_out = checkOut.toLocaleTimeString());
      min_days_high_season !== Number(highSeason) &&
        (payloadData.min_days_high_season = Number(highSeason));
      min_days_low_season !== Number(lowSeason) &&
        (payloadData.min_days_low_season = Number(lowSeason));

      dispatch(
        updateBookingSettings({
          id: settings.id,
          data: payloadData,
        })
      );
    }
  };

  useLayoutEffect(() => {
    if (settings) {
      const { check_in, check_out, min_days_high_season, min_days_low_season } = settings;

      check_in && setCheckIn(new Date(`1970-01-01T${check_in}`));
      check_out && setCheckOut(new Date(`1970-01-01T${check_out}`));
      min_days_high_season && setHighSeason(String(min_days_high_season));
      min_days_low_season && setLowSeason(String(min_days_low_season));
    }
  }, [settings]);

  useEffect(() => {
    if (checkIn && checkOut && checkIn.getTime() - checkOut.getTime() < 3600000) {
      setCheckInError("Время заезда должно быть минимум на 1 час больше времени выезда");
    } else {
      setCheckInError("");
    }
  }, [checkIn, checkOut]);

  const isFieldsChanged = useMemo(() => {
    if (settings) {
      const { check_in, check_out, min_days_high_season, min_days_low_season } = settings;

      return (
        (checkIn && check_in !== checkIn.toLocaleTimeString()) ||
        (checkOut && check_out !== checkOut.toLocaleTimeString()) ||
        min_days_high_season !== Number(highSeason) ||
        min_days_low_season !== Number(lowSeason)
      );
    }
  }, [settings, checkIn, checkOut, highSeason, lowSeason]);

  return (
    <>
      <div className={styles.checkWrapper}>
        <SelectTime
          title="Время заезда"
          value={checkIn}
          onChange={setCheckIn}
          placeholder="Укажите время"
          containerClassName={styles.checkInput}
          errText={checkInError}
        />
        <SelectTime
          title="Время выезда"
          value={checkOut}
          onChange={setCheckOut}
          placeholder="Укажите время"
          containerClassName={styles.checkInput}
          errText={checkInError}
        />
      </div>
      <h3 className={styles.contentTitle}>Минимальное количество дней в брони</h3>
      <div className={styles.minBookingDays}>
        <SelectComponentV2
          title="Высокий сезон"
          placeholder="—"
          optionsList={MIN_DAYS_OPTIONS}
          currentValue={highSeason}
          setSelecValue={setHighSeason}
          classnameTitleContainer={styles.daysCountLabel}
        />
        <SelectComponentV2
          title="Низкий сезон"
          placeholder="—"
          optionsList={MIN_DAYS_OPTIONS}
          currentValue={lowSeason}
          setSelecValue={setLowSeason}
          classnameTitleContainer={styles.daysCountLabel}
        />
      </div>
      <div className={styles.mainBtnsWrapper}>
        <Button
          title="Сохранить"
          type={ButtonType.PRIMARY_SMALL}
          onClick={onSaveObjectBtnClick}
          disabled={!isFieldsChanged}
          className={styles.mainBtn}
        />
        <Button
          title="Назад"
          type={ButtonType.SECONDARY_SMALL}
          path={`${RoutesList.objectManagement.objectEditingChild.fullPath}/${mainId}/${TabsNames.OBJECT_EDITING}`}
          className={styles.mainBtn}
        />
      </div>
    </>
  );
};

export default BookingSettings;
