import { InstagramIcon, TelegramIcon, TelephoneIcon } from "../../assets/icons";
import classNames from "classnames";
import styles from "./ContactsBlock.module.scss"

type ContactsBlockType ={
  classname?:string
}


const ContactsBlock = ({classname}:ContactsBlockType) => {


  return(
    <section className={classNames(styles.container, 
      classname&&styles[classname]
    )}>
      <div className={styles.column}>
        <a className={styles.linkItem} href="https://t.me/+TcNe1oCREvBjMGEy" target="blank">
          <div className={styles.icon}>
            <TelegramIcon 
              width={classname==="desktop"?"32px":"26px"}
              height={classname==="desktop"?"32px":"26px"}
            />
          </div>
          <span>Техподдержка</span>
        </a>
        <a className={styles.linkItem} href="https://t.me/+TcNe1oCREvBjMGEy" target="blank">
          <div className={styles.icon}>
            <TelegramIcon
              width={classname==="desktop"?"32px":"26px"}
              height={classname==="desktop"?"32px":"26px"}
            />
          </div>
          <span>Сообщество Витаем</span>
        </a>
      </div>
      <div className={styles.column}>
        <a className={styles.linkItem} href="tel:+375297516178">
          <div className={styles.icon}>
            <TelephoneIcon 
            width={classname==="desktop"?"27px":"22px"}
            height={classname==="desktop"?"27px":"22px"}
              fill="#A2BBFF"/>
          </div>
          <span>+375(29)751-6178</span>
        </a>
        <a className={styles.linkItem} href="https://www.instagram.com/vitaem_org/?hl=ru" target="blank">
          <div className={styles.icon}>
            <InstagramIcon
              width={classname==="desktop"?"32px":"28px"}
              height={classname==="desktop"?"32px":"28px"}
            />
          </div>
          <span>Vitaem</span>
        </a>
      </div>
    </section>
  )
}

export default ContactsBlock