import React from "react";

export const TelephoneIcon = ({ width = "16", height = "16", fill = "#0F2664" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.31232 1.3344L4.33007 1.33555C4.67933 1.3645 4.98267 1.53806 5.25959 1.80862C5.78582 2.32257 6.91581 3.82236 7.00112 4.23052C7.09554 4.68586 6.99459 4.9816 6.69364 5.50475L6.62069 5.63368C6.48259 5.88591 6.46148 5.99006 6.50424 6.10931C7.16133 7.71746 8.28262 8.83859 9.8724 9.4879C10.023 9.54243 10.1366 9.51118 10.4548 9.32865L10.6208 9.23473C11.0681 8.9894 11.3517 8.91186 11.7697 8.99893C12.1764 9.08415 13.6771 10.2149 14.1903 10.7404C14.4604 11.0171 14.6344 11.321 14.6647 11.6932C14.6966 12.598 13.5926 13.872 12.919 14.2551C12.1531 14.803 11.1812 14.7939 10.0913 14.2867C7.24142 13.1008 2.88203 8.76921 1.72029 5.9228L1.65074 5.76358C1.20961 4.70924 1.21917 3.79836 1.72469 3.11164C2.18595 2.34776 3.4296 1.29358 4.31232 1.3344ZM4.25803 2.30924L4.19926 2.31225C3.74619 2.37244 2.83764 3.15464 2.53506 3.65215L2.48279 3.72993C2.22214 4.15462 2.25235 4.73784 2.61524 5.53639L2.6846 5.69873C3.85495 8.3254 7.90716 12.3214 10.4839 13.394L10.6258 13.4571C11.3713 13.7731 11.9356 13.7591 12.3925 13.4351L12.4263 13.4134C12.5677 13.3152 12.8937 13.0189 13.1448 12.7291C13.4976 12.3221 13.6973 11.9492 13.6909 11.7507C13.6838 11.6658 13.6183 11.5513 13.4921 11.4221C13.1106 11.0314 11.7232 9.98598 11.5699 9.95385C11.4249 9.92365 11.3059 9.96424 10.9804 10.1514L10.8167 10.2434C10.3372 10.502 9.99922 10.5715 9.52168 10.3981C7.67102 9.64277 6.35717 8.32911 5.5938 6.4598C5.41299 5.95999 5.51032 5.60637 5.82476 5.05835L5.89967 4.92654C6.04252 4.66862 6.07275 4.5592 6.04583 4.42939C6.0136 4.27522 4.96888 2.8886 4.57767 2.50651C4.44813 2.37994 4.3343 2.31481 4.24911 2.30775L4.25803 2.30924Z"
        fill={fill}
      />
    </svg>
  );
};
