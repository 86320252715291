import { TarifAbilityType, TarifCardType, TarifTypes } from "../redux/types/@types"

export const tariffs:TarifCardType[]= [
  
  {
    label: "Бесплатный",
    code: TarifTypes.FREE,
    features:[
      "шахматка",
      "ручное заполнение брони",
      "один основной объект",
      "один сдаваемый объект"
    ]
  },
  {
    label: "Start",
    code: TarifTypes.START,
    features:[
      "шахматка",
      "ручное заполнение брони",
      "клиентская страница бронирования",
      "расширенное управление бронями",
      "один основной объект",
      "4 сдаваемых объекта"
    ]
  },  
  {
    label: "PRO",
    code: TarifTypes.PRO,
    features:[
      "шахматка",
      "ручное заполнение брони",
      "клиентская страница бронирования",
      "расширенное управление бронями",
      "два основных объекта",
      "до 10 сдаваемых объектов в каждом основном объекте"
    ]
  },
  {
    label: "PRO+",
    code: TarifTypes.PRO_PLUS,
    features:[
      "шахматка",
      "ручное заполнение брони",
      "клиентская страница бронирования",
      "расширенное управление бронями",
      "любое количество основных объектов",
      "любое количество сдаваемых объектов",
      // "личный менеджер"
    ]
  },    
]

export const tarifPossible:TarifAbilityType[] = [
  {
    title:"Календарь",
    features:[
      {
        subTitle:"Интерактивный календарь (шахматка)",
        tariffs:{
          free:true,
          start:true,
          pro: true,
          pro_plus: true
        }
      }
    ]
  },
  {
    title:"Брони",
    features:[
      {
        subTitle:"Персональная страница бронирования для социальных сетей (клиентская страница бронирования)",
        tariffs:{
          free:false,
          start:true,
          pro: true,
          pro_plus: true
        }
      },
      {
        subTitle:"Ручное внесение броней администратором",
        tariffs:{
          free:true,
          start:true,
          pro: true,
          pro_plus: true
        }
      },
      {
        subTitle:"Расширенное управление бронями",
        tariffs:{
          free:false,
          start:true,
          pro: true,
          pro_plus: true
        }
      },
    ]
  },
  {
    title:"Основные объекты",
    features:[
      {
        // subTitle:"Один основной объект, отображаемый в шахматке, на клиенте, при создании/изменении брони",
        subTitle:"Один основной объект",
        tariffs:{
          free:true,
          start:true,
          pro: false,
          pro_plus: false
        }
      },
      {
        // subTitle:"Два основных объекта отображаемых в шахматке, на клиенте, при создании/изменении брони, в списке броней",
        subTitle:"Два основных объекта",
        tariffs:{
          free:false,
          start:false,
          pro: true,
          pro_plus: false
        }
      },
      {
        // subTitle:"Неограниченное количество основных объектов отображаемых в шахматке, на клиенте, при создании/изменении брони, в списке броней",
        subTitle:"Неограниченное количество основных объектов",
        tariffs:{
          free:false,
          start:false,
          pro: false,
          pro_plus: true
        }
      },
    ]
  },
  {
    title:"Сдаваемые объекты",
    features:[
      {
        // subTitle:"Один сдаваемый объект отображаемый в шахматке, на клиенте, при создании/изменении брони",
        subTitle:"Один сдаваемый объект",
        tariffs:{
          free:true,
          start:false,
          pro: false,
          pro_plus: false
        }
      },
      {
        // subTitle:"1-10 сдаваемых объектов в каждом основном объекте отображаемых в шахматке, на клиенте, при создании/изменении брони, в списке броней",
        subTitle:"1-10 сдаваемых объектов в каждом основном объекте",
        tariffs:{
          free:false,
          start:true,
          pro: true,
          pro_plus: false
        }
      },
      {
        // subTitle:"Неограниченное количество сдаваемых объектов отображаемых в шахматке, на клиенте, при создании/изменении брони, в списке броней",
        subTitle:"Неограниченное количество сдаваемых объектов",
        tariffs:{
          free:false,
          start:false,
          pro: false,
          pro_plus: true
        }
      },
    ]
  },
]