import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from "react";
import styles from "./Prices.module.scss";
import Button from "../../../../components/Button";
import { EditIcon } from "../../../../assets/icons";
import {
  ButtonType,
  FieldTypes,
  InputType,
  OptionsListType,
  TabsNames,
} from "../../../../utils/@globalTypes";
import { RoutesList } from "../../../../App";
import { useParams } from "react-router-dom";
import Input from "../../../../components/Input";
import SelectComponentV2 from "../../../../components/SelectComponentV2";
import {
  getFormattedDateWithMonthAndDay,
  getCurrentYearMonthOptions,
  setFieldErrorText,
} from "../../../../utils/functions";
import {
  addCertainDayPricesStatus,
  PricesSelectors,
  updatePrices,
} from "../../../../redux/reducers/pricesSlice";
import { RentalObjectSelectors } from "../../../../redux/reducers/rentalObjectSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  CertainDayPriceType,
  PriceType,
  ResponseCertainDayPriceTypeList,
  ResponsePriceType,
} from "../../../../redux/types/pricesTypes";
import CertainDayPricesCard from "../../../../components/CertainDayPricesCard";
import { percentageMask, priceMask } from "../../../../utils/constants";

const currentYearMonth = getCurrentYearMonthOptions();

const MonthOptions: OptionsListType = [
  { value: "01", label: "Январь" },
  { value: "02", label: "Февраль" },
  { value: "03", label: "Март" },
  { value: "04", label: "Апрель" },
  { value: "05", label: "Май" },
  { value: "06", label: "Июнь" },
  { value: "07", label: "Июль" },
  { value: "08", label: "Август" },
  { value: "09", label: "Сентябрь" },
  { value: "10", label: "Октябрь" },
  { value: "11", label: "Ноябрь" },
  { value: "12", label: "Декабрь" },
];

const emptyDate = "XX-XX";

const Prices = () => {
  const dispatch = useDispatch();
  const { mainId, rentalId } = useParams();

  const currentPrices = useSelector(PricesSelectors.getPrices);
  const currentCertainDayPrices = useSelector(PricesSelectors.getCertainDayPrices);
  const certainDayPriceStatuses = useSelector(PricesSelectors.getCertainDayPriceStatuses);

  const [weekdayPrice, setWeekdayPrice] = useState("");
  const [weekendPrice, setWeekendPrice] = useState("");
  const [startDate, setStartDate] = useState(emptyDate);
  const [endDate, setEndDate] = useState(emptyDate);
  const [lowSeasonDiscount, setLowSeasonDiscount] = useState("");

  const [weekdayPriceError, setWeekdayPriceError] = useState("");
  const [weekendPriceError, setWeekendPriceError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [lowSeasonDiscountError, setLowSeasonDiscountError] = useState("");

  const [weekdayPriceTouched, setWeekdayPriceTouched] = useState(false);
  const [weekendPriceTouched, setWeekendPriceTouched] = useState(false);
  const [lowSeasonDiscountTouched, setLowSeasonDiscountTouched] = useState(false);

  const [certainDayPricesList, setCertainDayPricesList] = useState<ResponseCertainDayPriceTypeList>(
    []
  );
  const [newCertainDayPricesList, setNewCertainDayPricesList] =
    useState<ResponseCertainDayPriceTypeList>([]);

  const [isSave, setIsSave] = useState(false);

  const onSaveBtnClick = () => {
    setIsSave(true);
    setWeekdayPriceTouched(true);
    setWeekendPriceTouched(true);
    setLowSeasonDiscountTouched(true);

    if (
      currentPrices &&
      !weekdayPriceError &&
      !weekendPriceError &&
      !lowSeasonDiscountError &&
      weekdayPrice.length > 0 &&
      weekendPrice.length > 0 &&
      lowSeasonDiscount.length > 0
    ) {
      const startDateValues = startDate.split("-");
      const endDateValues = endDate.split("-");

      dispatch(
        updatePrices({
          id: currentPrices.id,
          data: {
            start_date: getFormattedDateWithMonthAndDay(+startDateValues[0], +startDateValues[1]),
            end_date: getFormattedDateWithMonthAndDay(+endDateValues[0], +endDateValues[1]),
            weekday_price: weekdayPrice,
            weekend_price: weekendPrice,
            low_season_discount: lowSeasonDiscount,
          },
        })
      );
    }
  };

  const onAddCertianDayPriceClick = () => {
    if (rentalId) {
      const randomId = Math.random() + Math.random();

      dispatch(
        addCertainDayPricesStatus({
          id: randomId,
          isNew: true,
          isLoading: false,
          isSuccess: false,
        })
      );

      setNewCertainDayPricesList((prevState) => [
        ...prevState,
        {
          id: randomId,
          start_date: "",
          end_date: "",
          price: "",
          rental_object: +rentalId,
        },
      ]);
    }
  };

  const setDate =
    (set: React.Dispatch<React.SetStateAction<string>>, index: 0 | 1) => (value: string) => {
      set((prevState) => {
        const str = prevState.split("-");
        return index === 0
          ? getFormattedDateWithMonthAndDay(+value, "XX")
          : getFormattedDateWithMonthAndDay(+str[0], +value);
      });
    };

  useLayoutEffect(() => {
    if (currentPrices) {
      const { weekday_price, weekend_price, end_date, start_date, low_season_discount } =
        currentPrices;
      weekday_price && setWeekdayPrice(weekday_price);
      weekend_price && setWeekendPrice(weekend_price);
      start_date && setStartDate(start_date);
      end_date && setEndDate(end_date);
      low_season_discount && setLowSeasonDiscount(low_season_discount);
    }
  }, [currentPrices]);

  useLayoutEffect(() => {
    setCertainDayPricesList(currentCertainDayPrices);
  }, [currentCertainDayPrices]);

  // WeekdayPrice

  useEffect(() => {
    setFieldErrorText(
      weekdayPriceTouched,
      weekdayPrice,
      setWeekdayPriceError,
      FieldTypes.DEFAULT,
      true
    );
  }, [weekdayPriceTouched, weekdayPrice]);

  // WeekendPrice

  useEffect(() => {
    setFieldErrorText(
      weekendPriceTouched,
      weekendPrice,
      setWeekendPriceError,
      FieldTypes.DEFAULT,
      true
    );
  }, [weekendPriceTouched, weekendPrice]);

  // LowSeasonDiscount

  useEffect(() => {
    setFieldErrorText(
      lowSeasonDiscountTouched,
      lowSeasonDiscount,
      setLowSeasonDiscountError,
      FieldTypes.DEFAULT,
      true
    );
  }, [lowSeasonDiscountTouched, lowSeasonDiscount]);

  // EndDate & StartDate

  useEffect(() => {
    if (endDate === startDate && !endDate.includes("XX") && !startDate.includes("XX")) {
      setEndDateError("Дата начала и окончания не может быть одинаковыми");
      setStartDateError("Дата начала и окончания не может быть одинаковыми");
    } else if (endDate !== startDate && !endDate.includes("XX") && !startDate.includes("XX")) {
      setEndDateError("");
      setStartDateError("");
    } else if (!endDate.includes("XX")) {
      setEndDateError("");
    } else if (!startDate.includes("XX")) {
      setStartDateError("");
    }
  }, [endDate, startDate]);

  // EndDate

  useEffect(() => {
    if (isSave && endDate.includes("XX")) {
      setEndDateError("Поля обязательны для заполнения");
    }
  }, [endDate, isSave]);

  // StartDate

  useEffect(() => {
    if (isSave && startDate.includes("XX")) {
      setStartDateError("Поля обязательны для заполнения");
    }
  }, [startDate, isSave]);

  const isValid = useMemo(() => {
    return !weekdayPriceError && !weekendPriceError && !lowSeasonDiscountError;
  }, [weekdayPriceError, weekendPriceError, lowSeasonDiscountError]);

  const isFieldsChanged = useMemo(() => {
    if (currentPrices) {
      const copyPrices: PriceType = {
        weekday_price: currentPrices.weekday_price ?? "",
        weekend_price: currentPrices.weekend_price ?? "",
        start_date: currentPrices.start_date ?? "XX-XX",
        end_date: currentPrices.end_date ?? "XX-XX",
        low_season_discount: currentPrices.low_season_discount ?? "",
      };

      const { weekday_price, weekend_price, end_date, start_date, low_season_discount } =
        copyPrices;

      return (
        +weekday_price !== +weekdayPrice ||
        +weekend_price !== +weekendPrice ||
        end_date !== endDate ||
        start_date !== startDate ||
        +low_season_discount !== +lowSeasonDiscount
      );
    }
  }, [currentPrices, weekdayPrice, weekendPrice, endDate, startDate, lowSeasonDiscount]);

  return rentalId ? (
    <>
      <h3 className={styles.contentTitle}>Цены проживания в высокий сезон (в выбранной валюте)</h3>
      <div className={styles.currency}>
        <div className={styles.currencyTitle}>Цены указываются в BYN</div>
        {/* <Button
          title={<EditIcon />}
          type={ButtonType.SMALL}
          path={`${RoutesList.objectManagement.fullPath}/${mainId}/${RoutesList.objectManagement.rentalObjectEditingChild.part}/${rentalId}/${TabsNames.BOOKING_SETTINGS}`}
        /> */}
      </div>
      <div className={styles.settingsFields}>
        <div className={styles.prices}>
          <Input
            value={weekdayPrice}
            title="Цена в будние дни (за сутки)*"
            placeholder="0.00"
            type={InputType.MASK}
            onChange={setWeekdayPrice}
            errText={weekdayPriceError}
            onBlur={setWeekdayPriceTouched}
            containerClassName={styles.field}
            mask={priceMask}
          />
          <Input
            value={weekendPrice}
            title="Цена в выходные дни (за сутки)*"
            placeholder="0.00"
            type={InputType.MASK}
            onChange={setWeekendPrice}
            errText={weekendPriceError}
            onBlur={setWeekendPriceTouched}
            containerClassName={styles.field}
            mask={priceMask}
          />
          <Input
            value={lowSeasonDiscount}
            title="Скидка на низкий сезон*"
            placeholder="0%"
            type={InputType.MASK}
            onChange={setLowSeasonDiscount}
            errText={lowSeasonDiscountError}
            onBlur={setLowSeasonDiscountTouched}
            requirementsText={"Значение в % (% от стоимости высокого сезона)"}
            containerClassName={styles.field}
            mask={percentageMask}
          />
        </div>
        <div className={styles.dates}>
          <div>
            <p className={styles.label}>Дата начала высокого сезона*</p>
            <div className={styles.date}>
              <SelectComponentV2
                title="Месяц"
                placeholder="—"
                optionsList={MonthOptions}
                currentValue={startDate.split("-")[0] === "XX" ? "" : startDate.split("-")[0]}
                setSelecValue={setDate(setStartDate, 0)}
                className={styles.month}
                errText={startDateError && "no-text"}
              />
              <SelectComponentV2
                title="День"
                placeholder="—"
                optionsList={currentYearMonth[startDate.split("-")[0]]}
                currentValue={startDate.split("-")[1] === "XX" ? "" : startDate.split("-")[1]}
                setSelecValue={setDate(setStartDate, 1)}
                maxMenuHeight={200}
                errText={startDateError && "no-text"}
                isDisabled={startDate.split("-")[0] === "XX"}
              />
            </div>
            {startDateError && <p className={styles.errorText}>{startDateError}</p>}
          </div>
          <div>
            <p className={styles.label}>Дата окончания высокого сезона*</p>
            <div className={styles.date}>
              <SelectComponentV2
                title="Месяц"
                placeholder="—"
                optionsList={MonthOptions}
                currentValue={endDate.split("-")[0] === "XX" ? "" : endDate.split("-")[0]}
                setSelecValue={setDate(setEndDate, 0)}
                className={styles.month}
                errText={endDateError && "no-text"}
              />
              <SelectComponentV2
                title="День"
                placeholder="—"
                optionsList={currentYearMonth[endDate.split("-")[0]]}
                currentValue={endDate.split("-")[1] === "XX" ? "" : endDate.split("-")[1]}
                setSelecValue={setDate(setEndDate, 1)}
                maxMenuHeight={200}
                errText={endDateError && "no-text"}
                isDisabled={endDate.split("-")[0] === "XX"}
              />
            </div>
            {endDateError && <p className={styles.errorText}>{endDateError}</p>}
          </div>
        </div>
      </div>
      <div className={styles.mainBtnsWrapper}>
        <Button
          title="Сохранить"
          type={ButtonType.PRIMARY_SMALL}
          onClick={onSaveBtnClick}
          disabled={!isValid || !isFieldsChanged}
          className={styles.mainBtn}
        />
        <Button
          title={false ? "Отмена" : "Назад"}
          type={ButtonType.SECONDARY_SMALL}
          path={`${RoutesList.objectManagement.objectEditingChild.fullPath}/${mainId}/${TabsNames.OBJECT_EDITING}`}
          className={styles.mainBtn}
        />
      </div>
      <h3 className={styles.contentTitle}>Настройка цены на определенные даты</h3>
      {[...certainDayPricesList, ...newCertainDayPricesList].length > 0 && (
        <div className={styles.certainList}>
          {[...certainDayPricesList, ...newCertainDayPricesList].map((item) => {
            const status = certainDayPriceStatuses.find((status) => status.id === item.id);
            return status ? (
              <CertainDayPricesCard
                rentalId={+rentalId}
                key={item.id}
                data={item}
                status={status}
                setCertainDayPricesList={setNewCertainDayPricesList}
              />
            ) : null;
          })}
        </div>
      )}
      <Button
        title="Добавить"
        type={ButtonType.PRIMARY_SMALL}
        onClick={onAddCertianDayPriceClick}
        className={styles.addPriceBtn}
      />
    </>
  ) : null;
};

export default Prices;
