import React from 'react';
import classNames from 'classnames';

import styles from "./PriceCard.module.scss";
import { TarifCardType, TariffsValue, TarifTypes } from '../../redux/types/@types';
import { MarkerIcon } from '../../assets/icons';
import Button from '../Button';
import { ButtonType, ModalWindowTypes } from '../../utils/@globalTypes';
import { useDispatch } from 'react-redux';
import { setModalWindowData } from '../../redux/reducers/pageSlice';
import { CURRENCY } from '../../utils/constants';

type priceCardProps = {
  tarif:TarifCardType,
  isCurrent?:boolean 
  period: 12 | 6 | 1,
  prices:TariffsValue,
  isPaying: boolean,
  discont:{
    halfYear:number,
    year:number
  }, 
  displayYearPrice:string,
  displayMonthPrice:string
}

const PriceCard = ({
  tarif,
  isCurrent, 
  period,
  prices,
  isPaying,
  discont,
  displayYearPrice,
  displayMonthPrice 
}:priceCardProps) => {

  const {
    label,     
    features,
    code
  } = tarif;
  const {monthlyPrice, yearPrice, halfYearPrice} = prices;  

  const hedearLabel = isCurrent ? "текущий тариф" 
  : isPaying ? "выгодное предложение" : ""
  
  const feat = features.map(item=>{
    return(
      <div className={styles.line} key={item}>
        <div className={styles.icon}>
          <MarkerIcon/>
        </div>        
        <div>{item}</div>
      </div>
    )
  })
  const dispatch = useDispatch();
  const handlerActivate=()=>{
    dispatch(setModalWindowData({
      type: ModalWindowTypes.TARIFF_ACTIVATION, 
      data:{
        code:code,
        period:period,
        monthlyPrice:monthlyPrice,
        halfYearPrice:halfYearPrice,
        yearPrice:yearPrice,
        label:label,
        discont:discont,
        isCurrent:isCurrent
      }}))   
  }
  return (
    <div className={classNames(styles.container, {
      [styles.paying]:isPaying,
      [styles.current]:isCurrent      
    })}>
      <div className={styles.label}>
        {hedearLabel}
      </div>

      <h2 className={styles.header}>
        {label}
      </h2>      
      <>        
        <div className={styles.price}>          
          <span className={styles.second}>{" " + displayMonthPrice + " " +CURRENCY + " / "}</span>
          <span className={styles.third}>месяц</span>
        </div>
        <div className={styles.free}>          
          <span className={styles.second}>{" " + displayYearPrice + " " +CURRENCY + " / "}</span>
          <span className={styles.third}>год</span>
        </div>
      </>
      
      <div className={styles.featBlock}>
        {feat}
      </div>
     
      {(!isCurrent)&&
      <div className={styles.btnContainer}>
        <Button
          title={"Перейти"}
          type={ButtonType.PRIMARY_SMALL}  
          onClick={handlerActivate}        
        />
      </div>
      }
      {(isCurrent&&code!==TarifTypes.FREE)&&
      <div className={styles.btnContainer}>
        <Button
          title={"Продлить"}
          type={ButtonType.SECONDARY_SMALL}  
          onClick={handlerActivate}        
        />
      </div>
      }
    </div>
  )
}

export default PriceCard;