import React from "react";

export const TelegramIcon = ({ width = "35", height = "32", fill = "#A2BBFF" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M15.9817 19.89L19.8722 24.3132C21.3122 25.9512 22.0334 26.7708 22.7882 26.5704C23.5418 26.3712 23.801 25.2936 24.3182 23.1372L27.1862 11.1756C27.9842 7.85395 28.3826 6.19435 27.497 5.37474C26.6114 4.55514 25.0766 5.16474 22.007 6.38275L7.76771 12.0384C5.31249 13.014 4.08489 13.5012 4.00689 14.3388C3.9977 14.4241 3.9977 14.5102 4.00689 14.5956C4.08249 15.4344 5.30769 15.9252 7.76051 16.908C8.87051 17.3532 9.42611 17.5764 9.82452 18.0024C9.86932 18.0504 9.91252 18.1 9.95412 18.1512C10.3213 18.6072 10.4773 19.2072 10.7905 20.4024L11.3773 22.6428C11.6809 23.8068 11.8333 24.39 12.2329 24.4692C12.6325 24.5484 12.9793 24.066 13.6741 23.1L15.9817 19.89ZM15.9817 19.89L15.6013 19.494C15.1669 19.0404 14.9497 18.8148 14.9497 18.534C14.9497 18.2532 15.1657 18.0264 15.6013 17.574L19.889 13.1052" 
        stroke={fill} 
        strokeWidth="1.8" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
};
