import React, { FC, useEffect, useState } from "react";
import Select, { components, DropdownIndicatorProps, OnChangeValue } from "react-select";
import "./SelectComponentV2.scss";
import classNames from "classnames";
import { OptionType, OptionsListType } from "../../utils/@globalTypes";
import { Arrow2Icon } from "../../assets/icons";

type SelectComponentV2Props = {
  id?: string;
  title: string;
  placeholder: string;
  isDisabled?: boolean;
  optionsList?: OptionsListType | null;
  currentValue: string;
  setSelecValue: (value: any) => void;
  defaultValueId?: number;
  isSearchable?: boolean;
  isClearable?: boolean;
  errText?: string | "no-text";
  onBlur?: (value: boolean) => void;
  isLoading?: boolean;
  data?: any;
  isBooking?: boolean; // отдельный класс и стили для использования в карточках редактирования/создания брони
  isTime?: boolean; // отдельный класс и стили ввода времени в брони
  classnameTitleContainer?: string;
  className?: string;
  maxMenuHeight?: number;
  formikBlur?: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
};

const SelectComponentV2: FC<SelectComponentV2Props> = ({
  id,
  title,
  placeholder,
  isDisabled,
  optionsList,
  currentValue,
  setSelecValue,
  isSearchable,
  defaultValueId,
  isClearable,
  errText,
  isLoading,
  onBlur,
  isBooking,
  isTime,
  classnameTitleContainer,
  className,
  maxMenuHeight,
  formikBlur,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
    return (
      <components.DropdownIndicator {...props}>
        <Arrow2Icon />
      </components.DropdownIndicator>
    );
  };

  const onBlurSelect = () => {
    if (formikBlur) {
      formikBlur(id);
    } else {
      setIsFocused(false);
      onBlur && onBlur(true);
    }
  };

  const getValue = () => {
    return currentValue && optionsList
      ? optionsList.find((option) => option.value === currentValue)
      : null;
  };

  const onChange = (newValue: OnChangeValue<OptionType, boolean>) => {
    setSelecValue(newValue ? (newValue as OptionType).value : "");
  };

  useEffect(() => {
    defaultValueId !== undefined && optionsList && onChange(optionsList[defaultValueId]);
  }, [defaultValueId]);

  return (
    <div>
      <div
        className={classNames("customSelectV2__wrapper", className, {
          "customSelectV2-indicator": currentValue.length > 0 || errText,
          ["booking"]: isBooking,
          ["time"]: isTime,
        })}
      >
        <div
          className={classNames("customSelectV2__title", classnameTitleContainer, {
            "customSelectV2__title--menuOpened": isMenuOpened,
            "customSelectV2__title--focusedFilled": isFocused && currentValue.length > 0,
            "customSelectV2__title--filled": currentValue.length > 0 && !isFocused,
            "customSelectV2__title--error": errText,
          })}
        >
          {title}
        </div>
        <Select
          components={{ DropdownIndicator }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor:
                errText && currentValue.length > 0
                  ? "#b91212"
                  : currentValue.length > 0 && state.isFocused
                  ? "#0a3a86"
                  : currentValue.length > 0
                  ? "#1855b7"
                  : "#FFFFFF",
              color:
                currentValue.length > 0 || (errText && currentValue.length > 0)
                  ? "#FFFFFF"
                  : "#696f79",
              border: errText
                ? "1px solid #b91212"
                : state.isFocused || currentValue.length > 0
                ? "0"
                : "1px solid #696f79",
              borderLeft: "0",
              boxShadow: state.isFocused ? "0px 4px 7px 3px rgba(24, 85, 183, 0.11)" : "none",
              transition: "0.3s",
            }),
            option: (baseStyles, { isFocused }) => ({
              ...baseStyles,
              color: isFocused ? "#0a3a86" : "#1855b7",
            }),

            // noOptionsMessage: (baseStyles, state) => ({
            //   ...baseStyles,
            //   padding: "5px 10px",
            //   color: "#696f79",
            // }),
          }}
          onChange={onChange}
          value={getValue()}
          className="customSelectV2"
          classNamePrefix="customSelectV2"
          isClearable={isClearable}
          isSearchable={isSearchable || false}
          options={optionsList !== null ? optionsList : undefined}
          placeholder={placeholder}
          isDisabled={isDisabled}
          unstyled
          noOptionsMessage={() => "Нет вариантов"}
          isLoading={isLoading}
          onBlur={onBlurSelect}
          onFocus={() => {
            setTimeout(() => setIsFocused(true), 0);
          }}
          onMenuOpen={() => {
            setTimeout(() => setIsMenuOpened(true), 0);
          }}
          onMenuClose={() => {
            setIsMenuOpened(false);
          }}
          maxMenuHeight={maxMenuHeight ?? 120}
        />
      </div>
      {errText && errText !== "no-text" && <p className="customSelectV2__errText">{errText}</p>}
    </div>
  );
};

export default SelectComponentV2;
